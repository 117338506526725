import React from "react";
import MuiTable from 'material-table';

export default function MaterialTable({options, components, localization, loading, ...props}){
  return (<MuiTable
    {...props}
    options={{
      filtering: false,
      search: false,
      pageSize: 10,
      actionsColumnIndex: props.columns.length,
      ...options
    }}
    localization={{
      pagination:{
        labelRowsSelect: 'rindas',
        labelDisplayedRows: `{from}-{to} no {count}`,
        firstTooltip: 'Pirmā lapa',
        nextTooltip: 'Nākamā lapa',
        previousTooltip: 'Iepriekšējā lapa',
        lastTooltip: 'Pēdējā lapa',

      },
      body:{
        emptyDataSourceMessage: !loading?'Nav datu':''
      },
      header:{
        actions: ''
      },
      ...localization
    }}
    components={{ // hide toolbar & groupbar by default
      Toolbar: ()=>null,
      Groupbar: ()=>null,
      ...components
    }}
  />)
}
