import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CompanyUsers from "./CompanyUsers";
import CloseIcon from '@material-ui/icons/Close';

export default function CompanyAdminUsersDialog({setOpen, companyId, companyName}) {
    const onClose = () => {
        setOpen(false);
    }
    return (
        <Dialog open
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                maxWidth="xl">
            <DialogTitle id="form-dialog-title">
                <div style={{marginRight: '30px'}}>Uzņēmuma "{companyName}" administratori</div>
                <IconButton onClick={() => setOpen(false)} style={{position: "absolute", top: '5px', right: '5px'}}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <CompanyUsers companyId={companyId} companyAdmins />
            </DialogContent>
        </Dialog>
    )
}