import {useQuery} from "@apollo/client";
import {GET_COMPANY} from "@mcity/valmiera-app/src/apollo/queries";

export default function CompanyName({companyId}) {
    const { loading, error, data } = useQuery(GET_COMPANY, {
        fetchPolicy: 'network-only',
        variables: { id: companyId },
    });
    if(error) {
        return "Kļūda, saņēmot uzņēmuma nosaukumu"//error.message;
    }
    if(loading) {
        return "...";
    }
    return data.companies.company.name;
}