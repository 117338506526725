import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
// TODO: Move imageUtils to core
import { compressedImage } from '@mcity/core/lib/utils/imageUtils'
import CancelIcon from '@material-ui/icons/Cancel'
import { useBusinessAuth } from '@mcity/valmiera-core/src/components/providers/BusinessAuthProvider'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { useValmieraConfig } from '@mcity/valmiera-core/src/components/providers/ValmieraConfigProvider'
import {
  CREATE_OFFER,
  UPDATE_OFFER,
  UPDATE_OFFER_STATUS,
  CREATE_ATTACHMENT,
  SEND_NOTIFICATION,
} from '../../../gql/mutations'
import { GET_OFFER } from '../../../gql/queries'
import Loader from '@mcity/core/lib/components/elements/Loader'
import { useMutation, useQuery } from '@apollo/client'
import OfferDueDatePicker from '../../elements/OfferDueDatePicker'
import { SEND_NOTIF_TO } from '../PushNotifications'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const OFFER_STATUSES = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  FINISHED: 'FINISHED',
}

const GIFT_STATUSES = {
  Jā: true,
  Nē: false,
}

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  inputUpload: {
    display: 'none',
  },
  buttonAdd: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  button: {
    minWidth: 200,
  },
})
const defaultErrorMsg = 'Lauks ir obligāti jāaizpilda.'
export default function AddEditOfferDialog({ offerId, onClose, onSuccess }) {
  const isEditMode = Boolean(offerId)
  const config = useValmieraConfig()
  const { isAdmin, isCompanyAdmin, companyId } = useBusinessAuth()
  const [sendPushNotifications, setSendPushNotifications] = React.useState(
    false,
  )

  const { loading: loadingCompanyByIdData } = useQuery(GET_OFFER, {
    variables: {
      id: offerId,
    },
    skip: !isEditMode,
    onCompleted: data => {
      if (data?.offers?.offer) {
        setFormState({
          ...data.offers.offer,
          attachmentId: data.offers.offer.attachment
            ? data.offers.offer.attachment.id
            : '',
          attachmentName: data.offers.offer.attachment
            ? data.offers.offer.attachment.name
            : '',
        })
      }
    },
  })

  const [createOffer] = useMutation(CREATE_OFFER)

  const [updateOffer] = useMutation(UPDATE_OFFER)

  const [updateOfferStatus] = useMutation(UPDATE_OFFER_STATUS)

  const [createAttachment] = useMutation(CREATE_ATTACHMENT)

  const [sendNotification] = useMutation(SEND_NOTIFICATION)

  const classes = useStyles()
  // form data
  const [formState, setFormState] = React.useState({
    id: offerId,
    companyId: companyId,
    title: '',
    description: '',
    attachmentId: '',
    attachmentName: '',
    isGift: false,
    discount: 0,
    authenticatedDiscount: 0,
    declaredDiscount: 0,
    silverDiscount: 0,
    goldDiscount: 0,
    expiry: null,
    status: OFFER_STATUSES.PENDING,
  })
  const [formErrors, setFormErrors] = React.useState({})
  const [file, setFile] = React.useState()

  const onFieldChange = ({ target }) =>
    setFormState(_formState => ({ ..._formState, [target.name]: target.value }))

  const onFieldNumberChange = ({ target }) =>
    setFormState(_formState => ({
      ..._formState,
      [target.name]: parseInt(target.value),
    }))

  const onUpload = async event => {
    const uploadedFile = event.target.files[0]
    if (uploadedFile) {
      const compressedFile = await compressedImage(uploadedFile)
      setFile(compressedFile)
      setFormState(_formState => ({
        ..._formState,
        attachmentName: uploadedFile.name,
      }))
    }
  }

  async function imageUpload(attachment) {
    if (attachment.id) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      formData.append('file_id', attachment.id)
      formData.append('token', attachment.token)

      const url = config.uploadUrl
      const uploadResponse = await fetch(url, {
        method: 'POST',
        body: formData,
      })
      if (uploadResponse?.ok) return attachment.id
      else if (uploadResponse.status === 413)
        throw new Error('Fails ir pārāk liels')
    } else {
      throw new Error('unknown')
    }
  }

  const isFormValid = () => {
    const _errors = []
    if (!formState.expiry) _errors.expiry = defaultErrorMsg
    if (!formState.title) _errors.title = defaultErrorMsg

    setFormErrors(_errors)
    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return

    // prepare data
    const data = {
      id: isEditMode ? offerId : undefined,
      input: {
        companyId: formState.companyId,
        title: formState.title,
        description: formState.description,
        attachmentId: isEditMode ? formState.attachmentId : undefined,
        isGift: formState.isGift,
        discount: formState.discount,
        authenticatedDiscount: formState.authenticatedDiscount,
        declaredDiscount: formState.declaredDiscount,
        silverDiscount: formState.silverDiscount,
        goldDiscount: formState.goldDiscount,
        expiry: formState.expiry,
      },
    }
    let promise = Promise.resolve('Success')

    if (formState.attachmentName && !formState.attachmentId) {
      promise = createAttachment({
        variables: {
          input: {
            name: file.name,
          },
        },
      })
    }

    promise
      .then(response => {
        if (response.data) {
          const attachment = response.data.attachments.addAttachment
          data.input.attachmentId = attachment.id
          imageUpload(attachment)
        }

        if (!formState.attachmentId && !formState.attachmentName)
          data.input.attachmentId = null

        if (isEditMode) {
          if (isCompanyAdmin) {
            promise = updateOffer({
              variables: data,
            })
          }

          if (isAdmin) {
            const id = data.id
            promise = updateOfferStatus({
              variables: {
                id,
                status: formState.status,
              },
            }).then(({ data }) => {
              if (
                formState.status === OFFER_STATUSES.APPROVED &&
                sendPushNotifications
              )
                sendNotification({
                  variables: {
                    input: {
                      title: 'Lietotnē "Valmiera" jauns piedāvājums',
                      body:
                        'Uzzini, ko organizācijas un uzņēmumi Tev sarūpējuši!',
                      data: [
                        {
                          key: 'type',
                          value: 'OFFER',
                        },
                        {
                          key: 'id',
                          value: data?.offers?.changeStatus?.id,
                        },
                      ],
                      sendNotificationsTo: SEND_NOTIF_TO[1].value, // send notif only to registered users
                    },
                  },
                })
            })
          }

          promise
            .then(() => {
              onSuccess()
            })
            .catch(e => {})
        } else {
          createOffer({ variables: data })
            .then(() => onSuccess())
            .catch(console.error)
        }
      })
      .catch(e => {})
  }

  if (loadingCompanyByIdData) return <Loader />

  return (
    <Dialog fullWidth open onClose={onClose}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon
            className={classes.closeIcon}
            color="primary"
            onClick={onClose}
          />
        </Box>
        <Box px={2}>
          <DialogTitle className={classes.dialogTitle}>
            <Box display="flex" justifyContent="center">
              {isEditMode ? 'Labot piedāvājumu' : 'Jauns piedāvājums'}
            </Box>
          </DialogTitle>
          <DialogContent>
            <TextField
              variant="outlined"
              margin="dense"
              label="Nosaukums"
              fullWidth
              name="title"
              autoComplete="off"
              disabled={isAdmin}
              value={formState.title}
              onChange={onFieldChange}
              helperText={formErrors.title}
              error={Boolean(formErrors.title)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Apraksts"
              fullWidth
              multiline
              name="description"
              rows={5}
              autoComplete="off"
              disabled={isAdmin}
              value={formState.description}
              onChange={onFieldChange}
              helperText={formErrors.description}
              error={Boolean(formErrors.description)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Titulbilde"
              fullWidth
              name="attachmentId"
              autoComplete="off"
              disabled={isAdmin}
              value={formState.attachmentName}
              helperText={formErrors.attachmentId}
              error={Boolean(formErrors.attachmentId)}
              InputProps={{
                endAdornment: (
                  <>
                    {isCompanyAdmin && (
                      <InputAdornment position="end">
                        {formState.attachmentName ? (
                          <Button
                            fullWidth
                            onClick={() =>
                              setFormState(_formState => ({
                                ..._formState,
                                attachmentName: '',
                                attachmentId: '',
                              }))
                            }
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            Dzēst
                          </Button>
                        ) : (
                          <>
                            <input
                              accept="image/*"
                              className={classes.inputUpload}
                              id="contained-button-file"
                              type="file"
                              onChange={onUpload}
                            />
                            <label htmlFor="contained-button-file">
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                component="span"
                              >
                                Pievienot
                              </Button>
                            </label>
                          </>
                        )}
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
            />

            <TextField
              variant="outlined"
              type="number"
              margin="dense"
              label="Atlaide"
              fullWidth
              name="discount"
              autoComplete="off"
              disabled={isAdmin}
              value={formState.discount}
              onChange={onFieldNumberChange}
              helperText={formErrors.discount}
              error={Boolean(formErrors.discount)}
            />

            <TextField
              variant="outlined"
              type="number"
              margin="dense"
              label="Autentificēta lietotāja atlaide"
              fullWidth
              name="authenticatedDiscount"
              autoComplete="off"
              disabled={isAdmin}
              value={formState.authenticatedDiscount}
              onChange={onFieldNumberChange}
              helperText={formErrors.authenticatedDiscount}
              error={Boolean(formErrors.authenticatedDiscount)}
            />

            <TextField
              variant="outlined"
              type="number"
              margin="dense"
              label="Pašvaldības iedzīvotāja atlaide"
              fullWidth
              name="declaredDiscount"
              autoComplete="off"
              disabled={isAdmin}
              value={formState.declaredDiscount}
              onChange={onFieldNumberChange}
              helperText={formErrors.declaredDiscount}
              error={Boolean(formErrors.declaredDiscount)}
            />

            <TextField
              variant="outlined"
              type="number"
              margin="dense"
              label="Sudraba atlaide"
              fullWidth
              name="silverDiscount"
              autoComplete="off"
              disabled={isAdmin}
              value={formState.silverDiscount}
              onChange={onFieldNumberChange}
              helperText={formErrors.silverDiscount}
              error={Boolean(formErrors.silverDiscount)}
            />

            <TextField
              variant="outlined"
              type="number"
              margin="dense"
              label="Zelta atlaide"
              fullWidth
              name="goldDiscount"
              autoComplete="off"
              disabled={isAdmin}
              value={formState.goldDiscount}
              onChange={onFieldNumberChange}
              helperText={formErrors.goldDiscount}
              error={Boolean(formErrors.goldDiscount)}
            />

            <Box mt={1}>
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                error={Boolean(formErrors.isGift)}
              >
                <InputLabel>Dāvana</InputLabel>
                <Select
                  disabled={isAdmin || isCompanyAdmin}
                  value={formState.isGift}
                  onChange={event => {
                    setFormState(_formState => ({
                      ..._formState,
                      isGift: event.target.value,
                    }))
                  }}
                  labelWidth={40}
                >
                  {Object.entries(GIFT_STATUSES).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box mt={1}>
              <OfferDueDatePicker
                {...{ formState, setFormState, formErrors }}
                disabled={isAdmin}
              />
            </Box>
            {isEditMode && isAdmin && (
              <Box mt={1}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={Boolean(formErrors.expiry)}
                >
                  <InputLabel>Statuss</InputLabel>
                  <Select
                    value={formState.status}
                    onChange={event => {
                      setFormState(_formState => ({
                        ..._formState,
                        status: event.target.value,
                      }))
                    }}
                    labelWidth={40}
                  >
                    {Object.values(OFFER_STATUSES).map(x => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            {isAdmin && (
              <Box mt={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendPushNotifications}
                      color="primary"
                      onChange={({ target: { checked } }) => {
                        setSendPushNotifications(checked)
                      }}
                    />
                  }
                  label="Sūtīt push paziņojumus par šo piedāvājumu"
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Box mt={1} mr={2}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
              >
                {isEditMode ? 'Saglabāt' : 'Pievienot'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
