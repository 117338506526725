import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  TimePicker as MuiTimePicker
} from '@material-ui/pickers';
import lvLocale from "date-fns/locale/lv";

export default function DatePicker(props) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lvLocale}>
      <KeyboardDatePicker
        inputVariant="outlined"
        invalidDateMessage="date not valid"
        disableToolbar
        variant="inline"
        format="dd.MM.yyyy"
        margin="dense"
        inputProps={{readOnly: true}}
        autoOk
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}

export const TimePicker = (props) => {
  return (<MuiPickersUtilsProvider utils={DateFnsUtils} locale={lvLocale}>
    <MuiTimePicker
      clearable
      autoOk
      ampm={false}
      label="24 hours"
      inputVariant="outlined"
      size="small"
      {...props}
    /></MuiPickersUtilsProvider>)
};
