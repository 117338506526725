import React from 'react'
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { ModalProvider } from 'react-modal-hook'
import * as moment from 'moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'moment/locale/lv'
import MomentUtils from '@date-io/moment'
import ApolloProvider from './components/providers/ApolloProvider'
import ThemeProvider from './components/providers/ThemeProvider'
import ValmieraConfigProvider from '@mcity/valmiera-core/src/components/providers/ValmieraConfigProvider'
import SignIn from './components/views/SignIn'
import routes from './routes/routes'
import TopBar from './components/views/TopBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import BusinessAuthProvider, {
  useBusinessAuth,
} from '@mcity/valmiera-core/src/components/providers/BusinessAuthProvider'
import Transactions from './components/views/business/Transactions'
import Offers from './components/views/business/Offers'
import Profile from './components/views/business/Profile'
import Companies from './components/views/business/Companies'
import SnackBarProvider from '@mcity/core/lib/components/providers/SnackBarProvider'
import { SurveysListView } from './components/views/Surveys'
import { SurveyResponses } from './components/views/SurveyResponses'
import { SurveyCharts } from './components/views/SurveyCharts'
import PushNotifications from './components/views/PushNotifications'
import LoaderProvider from '@mcity/core/lib/components/providers/LoaderProvider'
import Events from './components/views/Events'
import CompanyHistory from './components/views/history/CompanyHistory'
import EventHistory from './components/views/history/EventHistory'
import OfferHistory from './components/views/history/OfferHistory'
import TransactionHistory from './components/views/history/TransactionHistory'
import SurveyHistory from './components/views/history/SurveyHistory'
import LatvijaLVUsers from './components/views/LatvijaLVUsers'
import {
  SuggestionsListView,
  SuggestionsMapView,
} from './components/views/ValmieraAdminSuggestions'

function CompanyAdminRoute({ children, ...rest }) {
  const { token, isCompanyAdmin, isAdmin } = useBusinessAuth()
  const isAuthorized = isCompanyAdmin || isAdmin
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthorized ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.login,
              state: {
                from: location,
                alert: token
                  ? {
                      severity: 'error',
                      message: 'Šīm lietotājam nav administrēšanas tiesību',
                    }
                  : undefined,
              },
            }}
          />
        )
      }
    />
  )
}

function GlobalAdminRoute({ children, ...rest }) {
  const { isAdmin: isGlobalAdmin } = useBusinessAuth()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isGlobalAdmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.login,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default function App() {
  return (
    <ValmieraConfigProvider>
      <ApolloProvider>
        <BusinessAuthProvider>
          <ThemeProvider>
            <LoaderProvider>
              <SnackBarProvider>
                <CssBaseline />
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                >
                  <Router>
                    <ModalProvider>
                      <Switch>
                        <Route path={routes.login}>
                          <SignIn />
                        </Route>
                        <GlobalAdminRoute exact path={routes.events}>
                          <TopBar>
                            <Events />
                          </TopBar>
                        </GlobalAdminRoute>
                        <GlobalAdminRoute
                          exact
                          path={routes.push_notifications}
                        >
                          <TopBar>
                            <PushNotifications />
                          </TopBar>
                        </GlobalAdminRoute>
                        <GlobalAdminRoute exact path={routes.latvija_users}>
                          <TopBar>
                            <LatvijaLVUsers />
                          </TopBar>
                        </GlobalAdminRoute>
                        <GlobalAdminRoute exact path={routes.suggestions.list}>
                          <TopBar>
                            <SuggestionsListView />
                          </TopBar>
                        </GlobalAdminRoute>
                        <GlobalAdminRoute exact path={routes.suggestions.map}>
                          <TopBar>
                            <SuggestionsMapView />
                          </TopBar>
                        </GlobalAdminRoute>
                        <GlobalAdminRoute
                          exact
                          path={routes.surveys.charts.view}
                        >
                          <TopBar>
                            <SurveyCharts />
                          </TopBar>
                        </GlobalAdminRoute>
                        <GlobalAdminRoute
                          exact
                          path={routes.surveys.responses.view}
                        >
                          <TopBar>
                            <SurveyResponses />
                          </TopBar>
                        </GlobalAdminRoute>
                        <GlobalAdminRoute exact path={routes.surveys.list}>
                          <TopBar>
                            <SurveysListView />
                          </TopBar>
                        </GlobalAdminRoute>
                        <GlobalAdminRoute exact path={routes.home}>
                          <TopBar>
                            <SuggestionsListView />
                          </TopBar>
                        </GlobalAdminRoute>
                        <CompanyAdminRoute exact path={routes.business.profile}>
                          <TopBar>
                            <Profile />
                          </TopBar>
                        </CompanyAdminRoute>
                        <CompanyAdminRoute exact path={routes.business.offers}>
                          <TopBar>
                            <Offers />
                          </TopBar>
                        </CompanyAdminRoute>
                        <CompanyAdminRoute
                          exact
                          path={routes.business.transactions}
                        >
                          <TopBar>
                            <Transactions />
                          </TopBar>
                        </CompanyAdminRoute>
                        <CompanyAdminRoute
                          exact
                          path={routes.business.companies}
                        >
                          <TopBar>
                            <Companies />
                          </TopBar>
                        </CompanyAdminRoute>
                        <CompanyAdminRoute
                          exact
                          path={routes.history.companies}
                        >
                          <TopBar>
                            <CompanyHistory />
                          </TopBar>
                        </CompanyAdminRoute>
                        <CompanyAdminRoute exact path={routes.history.events}>
                          <TopBar>
                            <EventHistory />
                          </TopBar>
                        </CompanyAdminRoute>
                        <CompanyAdminRoute exact path={routes.history.offers}>
                          <TopBar>
                            <OfferHistory />
                          </TopBar>
                        </CompanyAdminRoute>
                        <CompanyAdminRoute
                          exact
                          path={routes.history.transactions}
                        >
                          <TopBar>
                            <TransactionHistory />
                          </TopBar>
                        </CompanyAdminRoute>
                        <CompanyAdminRoute exact path={routes.history.surveys}>
                          <TopBar>
                            <SurveyHistory />
                          </TopBar>
                        </CompanyAdminRoute>
                      </Switch>
                    </ModalProvider>
                  </Router>
                </MuiPickersUtilsProvider>
              </SnackBarProvider>
            </LoaderProvider>
          </ThemeProvider>
        </BusinessAuthProvider>
      </ApolloProvider>
    </ValmieraConfigProvider>
  )
}
