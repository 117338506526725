import { gql } from '@apollo/client'

export const CREATE_OFFER = gql`
  mutation CreateOffer($input: OfferInput!) {
    offers {
      create(input: $input) {
        id
      }
    }
  }
`

export const UPDATE_OFFER = gql`
  mutation UpdateOffer($id: Long!, $input: OfferInput!) {
    offers {
      update(id: $id, input: $input) {
        id
      }
    }
  }
`

export const UPDATE_OFFER_STATUS = gql`
  mutation UpdateOfferStatus($id: Long!, $status: OfferStatus!) {
    offers {
      changeStatus(id: $id, status: $status) {
        id
      }
    }
  }
`

export const CREATE_COMPANY = gql`
  mutation CreateCompany($input: CompanyInput!) {
    companies {
      registerCompany(input: $input) {
        id
        name
        regNo
        phone
        status
      }
    }
  }
`

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: Long!, $input: CompanyInput!) {
    companies {
      updateCompany(id: $id, input: $input) {
        id
        name
        regNo
        phone
        status
      }
    }
  }
`

export const UPDATE_COMPANY_STATUS = gql`
  mutation UpdateCompanyStatus($id: Long!, $status: CompanyStatus!) {
    companies {
      updateCompanyStatus(id: $id, status: $status) {
        id
      }
    }
  }
`
export const CREATE_ATTACHMENT = gql`
  mutation CreateAttachment($input: AttachmentInput!) {
    attachments {
      addAttachment(input: $input) {
        id
        token
      }
    }
  }
`

export const CHANGE_COMPANY_USER_PASSWORD = gql`
  mutation ChangeCompanyUserPassword($username: String!, $password: String!) {
    users {
      changePassword(username: $username, password: $password)
    }
  }
`

export const CREATE_COMPANY_USERS = gql`
  mutation CreateCompanyAdminUser(
    $companyId: Long
    $users: [CompanyUserInput!]!
  ) {
    companies {
      addUsers(companyId: $companyId, users: $users) {
        username
        success
      }
    }
  }
`

export const CREATE_SURVEY = gql`
  mutation CreateSurvey($input: SurveyInput) {
    surveys {
      addSurvey(input: $input) {
        id
      }
    }
  }
`

export const DELETE_SURVEY = gql`
  mutation DeleteSurvey($id: Long!) {
    surveys {
      delete(id: $id)
    }
  }
`

export const SEND_NOTIFICATION = gql`
  mutation sendNotification($input: MessageInput!) {
    messages {
      sendNotification(input: $input) {
        id
      }
    }
  }
`

export const CREATE_EVENT = gql`
  mutation CreateEvent($input: EventInput!) {
    events {
      create(input: $input) {
        id
      }
    }
  }
`

export const UPDATE_EVENT = gql`
  mutation UpdateEvent($id: Long!, $input: EventInput!) {
    events {
      update(id: $id, input: $input) {
        id
      }
    }
  }
`

export const DELETE_EVENT = gql`
  mutation DeleteEvent($id: Long!) {
    events {
      delete(id: $id)
    }
  }
`
