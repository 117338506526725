import React, { useState } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import ListItemText from '@material-ui/core/ListItemText'
import SurveyQuestionEditor from './SurveyQuestionEditor'
import SaveOrCancelDialog from '../SaveOrCancelDialog'
import DynamicItemList from '../DynamicItemList'
import MuiAlert from '@material-ui/lab/Alert'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

const REQUIRED_QUESTION_COUNT = 1

const PERMIT_LEVEL_OPEN = 'OPEN_FOR_ALL'
const PERMIT_LEVEL_REGISTERED = 'REGISTERED_ONLY'
const PERMIT_LEVEL_DECLARED = 'DECLARED_ONLY'

const permitLevels = [
  PERMIT_LEVEL_OPEN,
  PERMIT_LEVEL_REGISTERED,
  PERMIT_LEVEL_DECLARED,
]

export const permitLevelLabels = {
  [PERMIT_LEVEL_OPEN]: 'Publiskā',
  [PERMIT_LEVEL_REGISTERED]: 'Reģistrētājiem',
  [PERMIT_LEVEL_DECLARED]: 'Deklarētājiem',
}

export const permitLevelCompareWeights = {
  [PERMIT_LEVEL_OPEN]: 0,
  [PERMIT_LEVEL_REGISTERED]: 1,
  [PERMIT_LEVEL_DECLARED]: 2,
}

export default function SurveyEditor({
  survey,
  setSurvey,
  complete,
  alert,
  loading,
  setOpen,
}) {
  const [title, setTitle] = useState(survey?.title)
  const [description, setDescription] = useState(survey?.description)
  const [startOn, setStartOn] = useState(survey?.startOn || null)
  const [endOn, setEndOn] = useState(survey?.endOn || null)
  const [permitLevel, setPermitLevel] = useState(false)
  const [questions, setQuestions] = useState(survey?.questions || [])

  const [errors, setErrors] = useState({})

  const [showQuestionEditor, setShowQuestionEditor] = useState(false)
  const [questionEditedIndex, setQuestionEditedIndex] = useState(null)

  const invalidateSurvey = () => {
    const errors = {}
    if (!title || title.length < 1) {
      errors.title = 'Šīs lauks ir obligāts'
    }
    if (!description || description.length < 1) {
      errors.description = 'Šīs lauks ir obligāts'
    }
    if (!startOn) {
      errors.startOn = 'Šīs lauks ir obligāts'
    }
    if (!endOn) {
      errors.endOn = 'Šīs lauks ir obligāts'
    }
    if (!permitLevel) {
      errors.permitLevel = 'Šīs lauks ir obligāts'
    }
    if (questions.length < REQUIRED_QUESTION_COUNT) {
      errors.questions = `Aptaujā jābūt vismaz ${REQUIRED_QUESTION_COUNT} jautājumam`
    }
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  function onProceed() {
    if (invalidateSurvey()) {
      setSurvey({
        title,
        description,
        startOn,
        endOn,
        questions,
        permitLevel,
      })
    }
  }

  function onRemoveQuestion(i) {
    const updated = [...questions]
    updated.splice(i, 1)
    setQuestions(updated)
  }

  function onAppendQuestion(question) {
    setQuestions([...questions, question])
  }

  function onUpdateQuestion(update, i) {
    const updated = [...questions]
    updated[i] = update
    setQuestions(updated)
  }

  function onSetQuestion(value) {
    setShowQuestionEditor(false)
    if (questions.length > questionEditedIndex) {
      onUpdateQuestion(value, questionEditedIndex)
    } else {
      onAppendQuestion(value)
    }
  }

  function onAddQuestionClick() {
    setQuestionEditedIndex(questions.length)
    setShowQuestionEditor(true)
  }

  function onStartEditingQuestion(i) {
    setQuestionEditedIndex(i)
    setShowQuestionEditor(true)
  }

  function onMovedQuestion({ from, to }) {
    const result = Array.from(questions)
    const [removed] = result.splice(from, 1)
    result.splice(to, 0, removed)
    setQuestions(result)
  }

  return (
    <SaveOrCancelDialog
      title="Jauna aptauja"
      onProceed={onProceed}
      setOpen={setOpen}
      complete={complete}
      alert={alert}
      loading={loading}
    >
      <Box display="flex" flexDirection="column" spacing={3}>
        {showQuestionEditor && (
          <SurveyQuestionEditor
            question={questions[questionEditedIndex]}
            questionNumber={questionEditedIndex + 1}
            setOpen={setShowQuestionEditor}
            setQuestion={onSetQuestion}
          />
        )}
        <Box mb={1}>
          <TextField
            fullWidth
            label="Nosaukums"
            variant="outlined"
            value={title}
            error={errors.title}
            helperText={errors.title}
            onChange={event => setTitle(event.target.value)}
          />
        </Box>
        <Box mb={1} mt={1}>
          <TextField
            fullWidth
            label="Īss apraksts"
            variant="outlined"
            multiline
            rows={5}
            value={description}
            error={errors.description}
            helperText={errors.description}
            onChange={event => setDescription(event.target.value)}
          />
        </Box>
        <Box
          mt={1}
          mb={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <KeyboardDatePicker
            autoOk
            label="Aptaujas sākums"
            variant="inline"
            inputVariant="outlined"
            value={startOn}
            onChange={value => setStartOn(value.format())}
            format="DD.MM.YYYY"
            InputAdornmentProps={{ position: 'start' }}
            error={errors.startOn}
          />
          <p style={{ marginLeft: 10, marginRight: 10 }}>līdz</p>
          <KeyboardDatePicker
            autoOk
            label="Aptaujas beigas"
            variant="inline"
            inputVariant="outlined"
            value={endOn}
            onChange={value => setEndOn(value.format())}
            format="DD.MM.YYYY"
            InputAdornmentProps={{ position: 'start' }}
            error={errors.endOn}
          />
        </Box>
        <Box mt={1} mb={1} display="flex" width={260}>
          <FormControl variant="outlined" fullWidth size="large">
            <InputLabel>Pieejamība</InputLabel>
            <Select
              label="Pieejamība"
              variant="outlined"
              color="primary"
              size="medium"
              value={permitLevel}
              error={errors.permitLevel}
              onChange={event => setPermitLevel(event.target.value)}
            >
              {permitLevels.map(permitLevel => (
                <MenuItem key={permitLevel} value={permitLevel}>
                  {permitLevelLabels[permitLevel]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {errors.questions && (
          <Box mt={2}>
            <MuiAlert severity="warning">{errors.questions}</MuiAlert>
          </Box>
        )}
        <DynamicItemList
          onRemove={onRemoveQuestion}
          onAppend={onAddQuestionClick}
          onEdit={onStartEditingQuestion}
          onMoved={onMovedQuestion}
        >
          {questions.map((question, questionIndex) => (
            <ListItemText
              key={questionIndex}
              primary={question.questionText}
              secondary={`${questionIndex + 1}. jautājums`}
            />
          ))}
        </DynamicItemList>
      </Box>
    </SaveOrCancelDialog>
  )
}
