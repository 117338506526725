import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import { format } from 'date-fns'
import { lv } from 'date-fns/locale'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TableChartIcon from '@material-ui/icons/TableChart'

export function getTextValue(text) {
  return text.texts[0].text
}
export default function SurveyResponsesTable({ survey, responses }) {
  const groupedResponses = {}
  responses.forEach(
    ({ userId, questionId, answerId, freeAnswer, responseTime }) => {
      if (!groupedResponses[userId]) {
        groupedResponses[userId] = {}
        groupedResponses[userId].responseTime = format(
          new Date(responseTime),
          'P p',
          { locale: lv },
        )
        groupedResponses[userId].answers = {}
      }
      const group = groupedResponses[userId]
      if (!group.answers[questionId]) {
        group.answers[questionId] = []
      }
      const question = survey.questions.find(
        question => question.baseId === questionId,
      )
      group.answers[questionId].push(
        freeAnswer ||
          getTextValue(question.answers.find(answer => answer.id === answerId)),
      )
    },
  )

  function exportToCSV() {
    const lineArray = []
    const headerRow = [
      'Lietotājs',
      'Laiks',
      ...survey.questions.map(question => getTextValue(question)),
    ]
    lineArray.push(headerRow.join(','))
    Object.keys(groupedResponses).forEach(userId => {
      const item = groupedResponses[userId]
      const answers = survey.questions.map(question =>
        item.answers[question.baseId]
          ? item.answers[question.baseId].join(' ')
          : '',
      )
      lineArray.push([userId, item.responseTime, ...answers].join(','))
    })
    const csvContent = lineArray.join('\n')
    const encodedUri = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(
      csvContent,
    )}`
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'export.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Lietotājs</TableCell>
              <TableCell>Laiks</TableCell>
              {survey.questions.map((question, index) => (
                <TableCell key={index}>
                  {getTextValue(question)}
                  {question.required && <span>*</span>}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(groupedResponses).map(userId => {
              const item = groupedResponses[userId]
              return (
                <TableRow key={`response-${userId}`}>
                  <TableCell>{userId}</TableCell>
                  <TableCell>{item.responseTime}</TableCell>
                  {survey.questions.map(question => {
                    return (
                      <TableCell
                        key={`user-${userId}-question-${question.baseId}`}
                      >
                        {item.answers[question.baseId]
                          ? item.answers[question.baseId].join(', ')
                          : ''}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        style={{ margin: 10 }}
        color="primary"
        startIcon={<TableChartIcon />}
        onClick={exportToCSV}
      >
        Eksportēt uz CSV
      </Button>
    </Box>
  )
}
