import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import MaterialTable from '../../elements/MaterialTable'
import { useQuery } from '@apollo/client'
import { GET_COMPANIES } from '../../../gql/queries'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import PersonIcon from '@material-ui/icons/Person'
import AddEditCompanyDialog from './AddEditCompany'
import { useSnackBar } from '@mcity/core/lib/components/providers/SnackBarProvider'
import CompanyAdminUsersDialog from './CompanyAdminUsersDialog'

const CompanyOrderBy = {
  ID: 'id',
  NAME: 'name',
  REGNO: 'regNo',
  STATUS: 'status',
}

export const columns = [
  { field: 'id', title: 'ID' },
  { field: 'name', title: 'Nosaukums' },
  { field: 'phone', title: 'Telefons' },
  { field: 'regNo', title: 'Reģistrācijas numurs' },
  { field: 'status', title: 'Statuss' },
]

const useStyles = makeStyles(theme => ({
  search: {
    maxWidth: 200,
  },
  searchIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  date: {
    maxWidth: 200,
  },
  autocomplete: {
    width: 200,
  },
}))

export default function Companies() {
  const classes = useStyles()
  const { showSnackBar } = useSnackBar()
  const [searchText, setSearchText] = React.useState('')
  const [rows, setRows] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [sortedBy, setSortedBy] = React.useState(CompanyOrderBy.ID)
  const [orderDesc, setOrderDesc] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [totalCount, setTotalCount] = React.useState(0)
  const [companyIdToEditDetails, setCompanyIdToEditDetails] = React.useState(
    null,
  )
  const [companyToEditUsers, setCompanyToEditUsers] = React.useState(null)
  const [
    showAddEditCompanyDialog,
    setShowAddEditCompanyDialog,
  ] = React.useState(false)

  const { data, loading, refetch } = useQuery(GET_COMPANIES, {
    variables: {
      filter: {
        nameLike: searchText,
        skip: page * rowsPerPage,
        limit: rowsPerPage,
      },
    },
  })

  React.useEffect(() => {
    const companies = data?.companies?.companies?.list
    const totalCount = data?.companies?.companies?.totalCount
    if (companies) {
      setRows(companies.map(({ __typename, ...company }) => company))
      setTotalCount(totalCount)
    }
  }, [data])

  return (
    <>
      {showAddEditCompanyDialog && (
        <AddEditCompanyDialog
          companyId={companyIdToEditDetails}
          onClose={() => {
            setCompanyIdToEditDetails(null)
            setShowAddEditCompanyDialog(false)
          }}
          onSuccess={() => {
            showSnackBar()
            setShowAddEditCompanyDialog(false)
            setCompanyIdToEditDetails(null)
            refetch()
          }}
        />
      )}
      {companyToEditUsers && (
        <CompanyAdminUsersDialog
          companyId={companyToEditUsers.id}
          companyName={companyToEditUsers.name}
          setOpen={setCompanyToEditUsers}
        />
      )}
      <Box
        px={2}
        mt={1}
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <TextField
          variant="standard"
          label="Search..."
          className={classes.search}
          value={searchText}
          onChange={({ target: { value } }) => setSearchText(value)}
          InputProps={{
            endAdornment: (
              <IconButton disabled>
                <InputAdornment position="end">
                  <SearchIcon onClick={null} />
                </InputAdornment>
              </IconButton>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setShowAddEditCompanyDialog(true)
          }}
        >
          New company
        </Button>
      </Box>
      <MaterialTable
        loading={loading}
        columns={columns}
        data={rows}
        components={{
          Pagination: props => (
            <TablePagination
              {...props}
              count={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={event => {
                setRowsPerPage(Number(event.target.value))
                props.onChangeRowsPerPage(event)
              }}
              onChangePage={(_, page) => setPage(page)}
            />
          ),
          Header: data => (
            <TableHead>
              <TableRow>
                {data.columns.map(col => (
                  <TableCell key={col.field}>
                    {col.sortId ? (
                      <TableSortLabel
                        active={col.sortId === sortedBy}
                        direction={orderDesc ? 'desc' : 'asc'}
                        onClick={() => {
                          if (col.sortId === sortedBy) setOrderDesc(!orderDesc)
                          else setOrderDesc(false)

                          setSortedBy(col.sortId)
                        }}
                      >
                        {col.title}
                      </TableSortLabel>
                    ) : (
                      col.title
                    )}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
          ),
        }}
        options={{
          pageSize: rowsPerPage,
        }}
        actions={[
          {
            icon: () => <PersonIcon />,
            onClick: (_, row) => {
              setCompanyToEditUsers(row)
            },
          },
          {
            icon: 'edit',
            onClick: (_, row) => {
              setCompanyIdToEditDetails(row.id)
              setShowAddEditCompanyDialog(true)
            },
          },
        ]}
      />
    </>
  )
}
