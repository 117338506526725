import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import SuggestionEdit from './SuggestionEdit'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { statusCompareWeights } from './SuggestionStatusRenderer'
import { Link } from 'react-router-dom'
import ImageIcon from '@material-ui/icons/Image'
import PicturesDialog from './PicturesDialog'
import { useModal } from 'react-modal-hook'
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  alerts: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  description: {
    maxWidth: '1200px',
  },
  small: {
    maxWidth: '200px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

export const suggestionTableColumns = [
  { id: 'status', label: 'Statuss', sortable: true },
  { id: 'creationDate', label: 'Izveidošanas datums', sortable: true },
  { id: 'description', label: 'Apraksts', sortable: false },
  { id: 'address', label: 'Adrese', sortable: false },
  { id: 'userId', label: 'Iesniedzējs', sortable: true },
  { id: 'responsible', label: 'Atbildīgais', sortable: true },
  { id: 'plannedDueDate', label: 'Izpildīšanas termiņš', sortable: true },
  { id: 'comments', label: 'Komentārs', sortable: false },
]

export default function SuggestionsTable({ suggestions }) {
  const classes = useStyles()
  const [alert, setAlert] = useState(null)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('creationDate')
  const [dialogImages, setDialogImages] = React.useState([])
  const [showPicturesDialog, hidePicturesDialog] = useModal(
    () =>
      dialogImages.length > 0 && (
        <PicturesDialog
          attachments={dialogImages}
          onClose={() => {
            hidePicturesDialog()
            setDialogImages([])
          }}
        />
      ),
    [dialogImages],
  )

  const onError = ({ message }) => {
    setAlert({ severity: 'error', message: message })
  }
  const onSuccess = ({ message }) => {
    setAlert({ severity: 'success', message })
  }
  const onCloseAlert = () => {
    setAlert(null)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const statusComparator = (a, b) => {
    const wa = statusCompareWeights[a.status]
    const wb = statusCompareWeights[b.status]
    if (wb < wa) {
      return -1
    }
    if (wb > wa) {
      return 1
    }
    return 0
  }

  const stringComparator = orderBy => (a, b) => {
    return `${a[orderBy]}`.localeCompare(b[orderBy])
  }

  const dateComparator = orderBy => (a, b) => {
    return new Date(b[orderBy]) - new Date(a[orderBy])
  }

  function getComparator(orderBy) {
    switch (orderBy) {
      case 'creationDate':
      case 'plannedDueDate':
        return dateComparator(orderBy)
      case 'responsible':
      case 'userId':
        return stringComparator(orderBy)
      case 'status':
        return statusComparator
    }
  }

  function getOrderComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => getComparator(orderBy)(a, b)
      : (a, b) => -getComparator(orderBy)(a, b)
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }
  return (
    <Paper className={classes.root}>
      <Snackbar
        open={alert !== null}
        autoHideDuration={6000}
        onClose={onCloseAlert}
      >
        <MuiAlert
          onClose={onCloseAlert}
          severity={alert ? alert.severity : 'info'}
        >
          {alert ? alert.message : ''}
        </MuiAlert>
      </Snackbar>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <colgroup>
            <col style={{ width: '1%' }} />
            <col style={{ width: '1%', minWidth: '70px' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '5%' }} />
            <col style={{ width: '20%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell align="center">Vieta</TableCell>
              <TableCell align="center">Attēls</TableCell>
              {suggestionTableColumns.map(({ id, label, sortable }) => (
                <TableCell key={id}>
                  {sortable ? (
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? order : 'asc'}
                      onClick={() => handleRequestSort(id)}
                    >
                      {label}
                      {orderBy === id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <p>{label}</p>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(suggestions, getOrderComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(suggestion => (
                <SuggestionEdit
                  key={suggestion.id}
                  suggestion={suggestion}
                  onSuccess={onSuccess}
                  onError={onError}
                >
                  <TableCell>
                    <Link
                      to={`/suggestions/map?lat=${suggestion.lat}&lng=${suggestion.lng}`}
                    >
                      <IconButton>
                        <LocationOnIcon />
                      </IconButton>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      disabled={suggestion.attachments.length < 1}
                      onClick={() => {
                        setDialogImages(suggestion.attachments)
                        showPicturesDialog(true)
                      }}
                    >
                      <ImageIcon />
                    </IconButton>
                  </TableCell>
                </SuggestionEdit>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 8, 10, 12, 20]}
          component="div"
          count={suggestions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Rindas lapā"
        />
      </TableContainer>
    </Paper>
  )
}
