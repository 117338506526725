import React from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import MaterialTable from '../../elements/MaterialTable'
import { useQuery } from '@apollo/client'
import { GET_TRANSACTIONS } from '../../../gql/queries'
import DatePicker from '../../elements/DatePicker'
import { lv } from 'date-fns/locale'
import { useBusinessAuth } from '@mcity/valmiera-core/src/components/providers/BusinessAuthProvider'
import { format } from 'date-fns'

const useStyles = makeStyles((theme)=>({
  date: {
    maxWidth: 200
  },
}));

const TransactionOrderBy = {
  TX_ID: 'TX_ID',
}

export const columns = [
  { field: 'id', title: 'Darījuma ID', sortId: TransactionOrderBy.TX_ID },
  { field: 'companyId', title: 'Uzņēmuma ID' },
  { field: 'dateTime', title: 'Datums, laiks' },
  { field: 'userId', title: 'Lietotāja ID' },
  { field: 'offerId', title: 'Piedāvājuma ID' },
  {
    field: 'description',
    title: 'Apraksts',
    cellStyle: {
      minWidth: 200,
    },
  },
  { field: 'userLoyalty', title: 'Lojalitātes līmenis' },
  { field: 'discountType', title: 'Atlaides tips' },
  { field: 'discountAmount', title: 'Atlaides apjoms' },
  { field: 'sum', title: 'Summa', render: ({ sum }) => sum ? `${sum} €` : '' },
]

const reshapedTransaction = ({ time, ...transaction }) => {
  return {
    dateTime: format(new Date(time), 'P p', { locale: lv }),
    ...transaction,
  }
}

export default function Transactions() {
  const { companyId, isAdmin: isGlobalAdmin } = useBusinessAuth()
  const classes = useStyles();
  const today = new Date()
  const firstDayCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1,
  )
  const lastDayCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0,
  )
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(firstDayCurrentMonth);
  const [selectedDateTo, setSelectedDateTo] = React.useState(lastDayCurrentMonth);
  const [rows, setRows] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [sortedBy, setSortedBy] = React.useState(TransactionOrderBy.TX_ID)
  const [orderDesc, setOrderDesc] = React.useState(true)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [totalCount, setTotalCount] = React.useState(0)

  const { loading } = useQuery(GET_TRANSACTIONS, {
    variables: {
      input: {
        companyId: isGlobalAdmin ? null : companyId,
        skip: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: sortedBy,
        descending: orderDesc,
        startTime: moment(selectedDateFrom).format(),
        endTime: moment(selectedDateTo).format(),
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data?.transactions?.CompanyTransactions?.list) {
        setRows(
          data.transactions.CompanyTransactions.list.map(reshapedTransaction),
        )
        setTotalCount(data.transactions.CompanyTransactions.totalCount)
      }
    },
  })

  return (
    <>
      <Box p={1.5}>
        <Box display="flex">
          <DatePicker className={classes.date}
                      value={selectedDateFrom}
                      inputVariant="standard"
                      label="No"
                      onChange={(date) => {
                        date && date.setHours(0, 0, 0, 0);
                        setSelectedDateFrom(date)
                      }}
          />
        <Box ml={2}>
          <DatePicker className={classes.date}
                      value={selectedDateTo}
                      inputVariant="standard"
                      label="Līdz"
                      onChange={(date) => {
                        date && date.setHours(23, 59, 59, 999);
                        setSelectedDateTo(date)
                      }}
          />
        </Box>
        </Box>
        <Box mt={1}>
          Darījumu skaits izvēlētā laika periodā: {totalCount}
        </Box>
      </Box>
      <MaterialTable
        loading={loading}
        columns={columns}
        data={rows}
        components={{
          Pagination: props => (
            <TablePagination
              {...props}
              count={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={event => {
                setRowsPerPage(Number(event.target.value))
                props.onChangeRowsPerPage(event)
              }}
              onChangePage={(_, page) => setPage(page)}
            />
          ),
          Header: data => (
            <TableHead>
              <TableRow>
                {data.columns.map(col => (
                  <TableCell key={col.field}>
                    {col.sortId ? (
                      <TableSortLabel
                        active={col.sortId === sortedBy}
                        direction={orderDesc ? 'desc' : 'asc'}
                        onClick={() => {
                          if (col.sortId === sortedBy) setOrderDesc(!orderDesc)
                          else setOrderDesc(false)

                          setSortedBy(col.sortId)
                        }}
                      >
                        {col.title}
                      </TableSortLabel>
                    ) : (
                      col.title
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          ),
        }}
        options={{
          pageSize: rowsPerPage,
        }}
      />
    </>
  )
}
