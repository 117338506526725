import React from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { CHECK_CREDENTIALS } from '@mcity/core/lib/gql/mutations'

const Context = React.createContext(null)

export const BUSINESS_TOKEN = 'business-token'
export const BUSINESS_USER = 'business-user'
export function useBusinessAuth() {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error(
      'useBusinessAuth must be used within a BusinessAuthProvider',
    )
  }
  return context
}
// Notez bien: user can be a company or Valmiera panel admin
export default function BusinessAuthProvider({ children }) {
  const [checkCredentials] = useMutation(CHECK_CREDENTIALS, {
    fetchPolicy: 'no-cache',
  })
  const { t } = useTranslation()
  const [token, setToken] = React.useState(localStorage.getItem(BUSINESS_TOKEN))
  const [userId, setUserId] = React.useState()
  const [isAdmin, setIsAdmin] = React.useState(false)
  const [isCompanyAdmin, setIsCompanyAdmin] = React.useState(false)
  const [username, setUsername] = React.useState()
  const [firstName, setFirstName] = React.useState()
  const [lastName, setLastName] = React.useState()
  const [companyId, setCompanyId] = React.useState()

  React.useEffect(() => {
    loadUserDataFromCache()
  }, [])

  const loadUserDataFromCache = () => {
    const userJson = localStorage.getItem(BUSINESS_USER)
    const userObj = userJson && JSON.parse(userJson)
    setUserId(userObj?.userId)
    setUsername(userObj?.username)
    setFirstName(userObj?.firstName)
    setLastName(userObj?.lastName)
    setCompanyId(userObj?.companyId)
    setIsAdmin(userObj?.isAdmin) // isAdmin = the user is a global admin
    setIsCompanyAdmin(userObj?.isCompanyAdmin)
  }

  const login = (username, password) => {
    return checkCredentials({
      variables: {
        input: {
          username,
          password,
        },
      },
    })
      .then(({ data }) => {
        if (data?.authentication?.checkCredentials) {
          const {
            token,
            params,
            usernameId,
          } = data.authentication.checkCredentials
          const firstNameParam = params.find(p => p.key === 'name')
          const lastNameParam = params.find(p => p.key === 'surname')
          const companyIdParam = params.find(p => p.key === 'companyId')
          const isAdminParam = params.find(p => p.key === 'isAdmin')
          const isCompanyAdminParam = params.find(p => p.key === 'companyAdmin')
          const firstName = firstNameParam?.value
          const lastName = lastNameParam?.value
          const companyId = companyIdParam?.value
          const isAdmin = isAdminParam?.value === 'true'
          const isCompanyAdmin = isCompanyAdminParam?.value === 'true'
          const userObject = {
            userId: usernameId,
            username,
            firstName,
            lastName,
            companyId,
            isAdmin,
            isCompanyAdmin,
          }
          localStorage.setItem(BUSINESS_TOKEN, token)
          localStorage.setItem(BUSINESS_USER, JSON.stringify(userObject))

          setToken(token)
          setUsername(username)
          setFirstName(firstName)
          setLastName(lastName)
          setCompanyId(companyId)
          setUserId(usernameId)
          setIsAdmin(isAdmin)
          setIsCompanyAdmin(isCompanyAdmin)

          return userObject
        } else {
          throw Error(t('login.error'))
        }
      })
      .catch(() => {
        throw Error(t('login.error'))
      })
  }

  const logout = () => {
    localStorage.removeItem(BUSINESS_TOKEN)
    localStorage.removeItem(BUSINESS_USER)
    setToken(null)
    setLastName(null)
    setFirstName(null)
    setUsername(null)
    setCompanyId(null)
    setUserId(null)
    setIsCompanyAdmin(false)
    setIsAdmin(false)
  }

  return (
    <Context.Provider
      value={{
        token,
        login,
        logout,
        isAdmin,
        isCompanyAdmin,
        userId,
        firstName,
        lastName,
        companyId,
        username,
      }}
    >
      {children}
    </Context.Provider>
  )
}
