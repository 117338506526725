import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import CancelIcon from '@material-ui/icons/Cancel'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import {
  CREATE_COMPANY,
  UPDATE_COMPANY_STATUS,
  UPDATE_COMPANY,
} from '../../../gql/mutations'
import { GET_COMPANY } from '../../../gql/queries'
import Loader from '@mcity/core/lib/components/elements/Loader'
import { useMutation, useQuery } from '@apollo/client'

const COMPANY_STATUSES = {
  ACTIVE: 'ACTIVE',
  REGISTERED: 'REGISTERED',
  DECLINED: 'DECLINED',
}

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
  button: {
    minWidth: 200,
  },
})
const defaultErrorMsg = 'Lauks ir obligāti jāaizpilda.'
export default function AddEditCompanyDialog({
  companyId,
  onClose,
  onSuccess,
}) {
  const isEditMode = Boolean(companyId)
  const { loading: loadingCompanyByIdData } = useQuery(GET_COMPANY, {
    variables: {
      id: companyId,
    },
    skip: !isEditMode,
    onCompleted: data => {
      if (data?.companies?.company) {
        setFormState(data.companies.company)
      }
    },
  })

  const [createCompany] = useMutation(CREATE_COMPANY)

  const [updateCompany] = useMutation(UPDATE_COMPANY)

  const [updateCompanyStatus] = useMutation(UPDATE_COMPANY_STATUS)

  const classes = useStyles()
  // form data
  const [formState, setFormState] = React.useState({
    name: '',
    regNo: '',
    phone: '',
    status: COMPANY_STATUSES.ACTIVE,
  })
  const [formErrors, setFormErrors] = React.useState({})

  const onFieldChange = ({ target }) =>
    setFormState(_formState => ({ ..._formState, [target.name]: target.value }))

  const isFormValid = () => {
    const _errors = []
    if (!formState.name) _errors.name = defaultErrorMsg
    if (!formState.regNo) _errors.regNo = defaultErrorMsg

    setFormErrors(_errors)
    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return

    if (isEditMode) {
      // prepare data
      const data = {
        id: companyId,
        input: {
          name: formState.name,
          regNo: formState.regNo,
          phone: formState.phone,
        },
      }
      console.log('updateCompany', data)
      updateCompany({
        variables: data,
      })
        .then(({ data }) => {
          const id = data?.companies?.updateCompany?.id
          updateCompanyStatus({
            variables: {
              id,
              status: formState.status,
            },
          }).then(() => {
            onSuccess()
          })
        })
        .catch(console.error)
    } else {
      // prepare data
      const data = {
        input: {
          name: formState.name,
          phone: formState.phone,
          regNo: formState.regNo,
        },
      }
      console.log('createCompany', data)
      createCompany({ variables: data })
        .then(id => onSuccess())
        .catch(console.error)
    }
  }

  if (loadingCompanyByIdData) return <Loader loading />

  console.log({ formState })
  return (
    <Dialog fullWidth open onClose={onClose}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon
            className={classes.closeIcon}
            color="primary"
            onClick={onClose}
          />
        </Box>
        <Box px={2}>
          <DialogTitle className={classes.dialogTitle}>
            <Box display="flex" justifyContent="center">
              {isEditMode ? 'Update company info' : 'New company'}
            </Box>
          </DialogTitle>
          <DialogContent>
            <TextField
              variant="outlined"
              margin="dense"
              label="Vārds"
              fullWidth
              name="name"
              autoComplete="off"
              value={formState.name}
              onChange={onFieldChange}
              helperText={formErrors.name}
              error={Boolean(formErrors.name)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Reģistrācijas numurs"
              fullWidth
              name="regNo"
              autoComplete="off"
              value={formState.regNo}
              onChange={onFieldChange}
              helperText={formErrors.regNo}
              error={Boolean(formErrors.regNo)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Telefons"
              fullWidth
              name="phone"
              autoComplete="off"
              value={formState.phone}
              onChange={onFieldChange}
              helperText={formErrors.phone}
              error={Boolean(formErrors.phone)}
            />

            {isEditMode && (
              <Box mt={1}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel>Stauss</InputLabel>
                  <Select
                    value={formState.status}
                    onChange={event => {
                      setFormState(_formState => ({
                        ..._formState,
                        status: event.target.value,
                      }))
                    }}
                    labelWidth={40}
                  >
                    {Object.values(COMPANY_STATUSES).map(x => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Box mt={1} mr={2}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
              >
                {isEditMode ? 'Saglabāt' : 'Pievienot'}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}
