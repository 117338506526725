import React from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import CancelIcon from '@material-ui/icons/Cancel'
import Box from "@material-ui/core/Box";
import { PieChart } from 'react-minimal-pie-chart'

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle:{
    paddingTop: 0,
    textTransform: 'uppercase'
  },
  dialogContent: {
    margin: '0 auto'
  },
  legendItem:{
    display: 'flex',
    alignItems: 'center',
    '& > div':{
      height: 30,
      width: 60
    },
    '& > p':{
      fontSize: 12,
      marginLeft: 6
    }
  },

}));

export default function UserChartDialog({onClose, totalUsers, totalDeclaredUsers,
                                          totalRegisteredUsers, totalGuests, totalSilverLoyaltyUsers,
                                          totalGoldLoyaltyUsers,totalDeclaredLoyaltyUsers, totalRegisteredLoyaltyUsers}) {
  const classes = useStyles();
  const theme = useTheme();
  const statusChartItems = [
    {
      title: 'Deklarēts',
      value: totalDeclaredUsers,
      color: theme.palette.primary.main
    },
    {
      title: 'Reģistrēts',
      value: totalRegisteredUsers,
      color: theme.palette.secondary.main
    },
    {
      title: 'Viesi',
      value: totalGuests,
      color: '#6A2135'
    }
  ]
  const loyaltyChartItems = [
    {
      title: 'Deklarēts',
      value: totalDeclaredLoyaltyUsers,
      color: theme.palette.primary.main
    },
    {
      title: 'Reģistrēts',
      value: totalRegisteredLoyaltyUsers,
      color: theme.palette.secondary.main
    },
    {
      title: 'Zelts',
      value: totalGoldLoyaltyUsers,
      color: '#b49016'
    },
    {
      title: 'Sudrabs',
      value: totalSilverLoyaltyUsers,
      color: '#868585'
    }
  ]
 return (
   <Dialog open
           fullWidth
           onClose={onClose}
           maxWidth="sm">
     <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
       <CancelIcon className={classes.closeIcon} color="primary" onClick={onClose}/>
     </Box>
     <DialogTitle className={classes.dialogTitle}>
       <Box display="flex" justifyContent="center">lietotāju skaits: (Kopā {totalUsers}) </Box>
     </DialogTitle>
     <DialogContent className={classes.dialogContent}>
       <Box display="flex" alignItems="center">
         <PieChart
           label={({ dataEntry }) => `${dataEntry.value}`}
           labelStyle={{
             fontSize: 6,
             fill: 'white'
           }}
           data={statusChartItems}
         />
         <Box
           ml={2}
         >
           {statusChartItems.map(({color, title}, i)=>(
             <div className={classes.legendItem} key={i}>
               <div
                 style={{ backgroundColor: color}}
               />
               <p>{title}</p>
             </div>
           ))}
         </Box>
       </Box>
       <Box mt={2} display="flex" alignItems="center">
         <PieChart
           label={({ dataEntry }) => `${dataEntry.value}`}
           labelStyle={{
             fontSize: 6,
             fill: 'white'
           }}
           data={loyaltyChartItems}
         />
         <Box
           ml={2}
         >
           {loyaltyChartItems.map(({color, title}, i)=>(
             <div className={classes.legendItem} key={i}>
               <div
                 style={{ backgroundColor: color}}
               />
               <p>{title}</p>
             </div>
           ))}
         </Box>
       </Box>
     </DialogContent>
   </Dialog>
 )
}
