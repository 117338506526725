import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_RESPONSES } from '../../gql/queries'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Error } from '@mcity/valmiera-app/src/components/elements'
import SurveyResponsesTable from '../elements/surveys/SurveyResponsesTable'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'

export function SurveyResponses() {
  const { id } = useParams()
  const history = useHistory()
  const { loading, error, data } = useQuery(GET_RESPONSES, {
    variables: { surveyId: id },
  })
  if (loading) {
    return <CircularProgress />
  }
  if (error) {
    return <Error>{error.message}</Error>
  }
  if (!data?.responses?.responses) {
    return <Error>No data to display</Error>
  }
  const survey = data.responses.responses.survey
  const responses = data.responses.responses.responses
  return (
    <div>
      <Box display="flex" flexDirection="row" alignItems="center">
        <IconButton onClick={() => history.goBack()}>
          <ArrowBackIcon />
        </IconButton>
        <h2>
          Visas atbildes uz aptauju '
          {survey.parameters.find(p => p.key === 'title').value}'
        </h2>
      </Box>
      <SurveyResponsesTable survey={survey} responses={responses} />
    </div>
  )
}
