import { useQuery } from '@apollo/client'
import { Error } from '@mcity/valmiera-app/src/components/elements'
import React from 'react'
import {
  GET_LATVIJA_LV_USERS,
  GET_TOTAL_GUESTS,
  GET_TOTAL_USERS_BY_LOYALTY,
} from '../../gql/queries'
import MaterialTable from '../elements/MaterialTable'
import TablePagination from '@material-ui/core/TablePagination'
import UserChartDialog from '../elements/UserChartDialog'
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button'
import PieChartIcon from '@material-ui/icons/PieChart';
import { GET_TOTAL_USERS_BY_STATUS } from '@mcity/valmiera-core/src/gql/queries'
export const columns = [
  { field: 'id', title: 'Lietotāja ID' },
  { field: 'name', title: 'Vārds' },
  { field: 'surname', title: 'Uzvārds' },
  { field: 'status', title: 'Statuss' },
]

function reshapeLatvijaUserData(user){
  const name = user.params.find(e=>e.key==='name')?.value;
  const surname = user.params.find(e=>e.key==='surname')?.value;
  return {
    id: user.id,
    status: user.status,
    name,
    surname,
  }
}

export default function LatvijaLVUsers() {
  const [rows, setRows] = React.useState([])
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [totalCount, setTotalCount] = React.useState(0)
  const [totalUsers, setTotalUsers] = React.useState(0)
  const [totalGuests, setTotalGuests] = React.useState(0)
  const [totalRegisteredUsers, setTotalRegisteredUsers] = React.useState(0)
  const [totalDeclaredUsers, setTotalDeclaredUsers] = React.useState(0)
  const [totalRegisteredLoyaltyUsers, setTotalRegisteredLoyaltyUsers] = React.useState(0)
  const [totalDeclaredLoyaltyUsers, setTotalDeclaredLoyaltyUsers] = React.useState(0)
  const [totalGoldLoyaltyUsers, setTotalGoldLoyaltyUsers] = React.useState(0)
  const [totalSilverLoyaltyUsers, setTotalSilverLoyaltyUsers] = React.useState(0)
  const [showUserChartDialog, setShowUserChartDialog] = React.useState(false)
  const { loading, error } = useQuery(GET_LATVIJA_LV_USERS, {
    fetchPolicy: 'network-only',
    variables: {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      filter: {
        authMethod: "LATVIALV"
      }
    },
    onCompleted: data => {
      const users = data?.users?.users
      if (users) {
        setRows(users.list)
        setTotalCount(users.totalCount)
      }
    },
  })
  // number of users by status
  useQuery(GET_TOTAL_USERS_BY_STATUS, {
    fetchPolicy: 'network-only',
    variables: {
      status: null
    },
    onCompleted: data => {
      const count = data?.users?.users?.count
      setTotalUsers(count)
    },
  })
  useQuery(GET_TOTAL_USERS_BY_STATUS, {
    fetchPolicy: 'network-only',
    variables: {
      status: 'DECLARED'
    },
    onCompleted: data => {
      const count = data?.users?.users?.count
      setTotalDeclaredUsers(count)
    },
  })
  useQuery(GET_TOTAL_USERS_BY_STATUS, {
    fetchPolicy: 'network-only',
    variables: {
      status: 'REGISTERED'
    },
    onCompleted: data => {
      const count = data?.users?.users?.count
      setTotalRegisteredUsers(count)
    },
  })
  useQuery(GET_TOTAL_GUESTS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const count = data?.guests?.count
      setTotalGuests(count)
    },
  })
  // number of users by loyalty
  useQuery(GET_TOTAL_USERS_BY_LOYALTY, {
    fetchPolicy: 'network-only',
    variables: {
      loyalty: 'GOLD'
    },
    onCompleted: data => {
      const count = data?.users?.users?.count
      setTotalGoldLoyaltyUsers(count)
    },
  })
  useQuery(GET_TOTAL_USERS_BY_LOYALTY, {
    fetchPolicy: 'network-only',
    variables: {
      loyalty: 'SILVER'
    },
    onCompleted: data => {
      const count = data?.users?.users?.count
      setTotalSilverLoyaltyUsers(count)
    },
  })
  useQuery(GET_TOTAL_USERS_BY_LOYALTY, {
    fetchPolicy: 'network-only',
    variables: {
      loyalty: 'REGISTERED'
    },
    onCompleted: data => {
      const count = data?.users?.users?.count
      setTotalRegisteredLoyaltyUsers(count)
    },
  })
  useQuery(GET_TOTAL_USERS_BY_LOYALTY, {
    fetchPolicy: 'network-only',
    variables: {
      loyalty: 'DECLARED'
    },
    onCompleted: data => {
      const count = data?.users?.users?.count
      setTotalDeclaredLoyaltyUsers(count)
    },
  })

  if (error) {
    return <Error>{error.message}</Error>
  }

  return (
    <>
      {showUserChartDialog&&<UserChartDialog
        totalUsers={totalUsers+totalGuests}
        totalDeclaredUsers={totalDeclaredUsers}
        totalRegisteredUsers={totalRegisteredUsers}
        totalGuests={totalGuests}
        totalSilverLoyaltyUsers={totalSilverLoyaltyUsers}
        totalGoldLoyaltyUsers={totalGoldLoyaltyUsers}
        totalDeclaredLoyaltyUsers={totalDeclaredLoyaltyUsers}
        totalRegisteredLoyaltyUsers={totalRegisteredLoyaltyUsers}
        onClose={()=>setShowUserChartDialog(false)}
      />}
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          startIcon={<PieChartIcon />}
          onClick={() => {
            setShowUserChartDialog(true)
          }}
        >
          lietotāju skaits
        </Button>
      </Box>
    <MaterialTable
      loading={loading}
      columns={columns}
      data={rows.map(reshapeLatvijaUserData)}
      components={{
        Pagination: props => (
          <TablePagination
            {...props}
            count={totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={event => {
              setRowsPerPage(Number(event.target.value))
              props.onChangeRowsPerPage(event)
            }}
            onChangePage={(_, page) => setPage(page)}
          />
        ),
      }}
      options={{
        pageSize: rowsPerPage,
        sorting: false
      }}
    />
    </>
  )
}
