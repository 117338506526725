import React from 'react'
import { PieChart } from 'react-minimal-pie-chart'
import Box from '@material-ui/core/Box'
import { getTextValue } from './SurveyResponsesTable'

const colors = [
  '#0074D9',
  '#FF4136',
  '#2ECC40',
  '#ff851b',
  '#7FDBFF',
  '#B10DC9',
  '#FFDC00',
  '#001f3f',
  '#39CCCC',
  '#01FF70',
  '#85144b',
  '#F012BE',
  '#3D9970',
  '#111111',
  '#AAAAAA',
]

export default function SurveyResponseChart({ question, responses }) {
  const frequencies = responses.reduce(function (result, response) {
    const key = response.answerId || 'unanswered'
    if (!result[key]) {
      result[key] = 0
    }
    result[key] += 1
    return result
  }, {})
  const data = Object.keys(frequencies).map((key, i) => {
    const title =
      key === 'unanswered'
        ? 'Bez atbildes'
        : getTextValue(
            question.answers.find(answer => answer.id.toString() === key),
          )
    return {
      title: title,
      value: frequencies[key],
      color: colors[i],
    }
  })
  return (
    <Box
      mb={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      alignContent="center"
    >
      <h1>{getTextValue(question)}</h1>
      {data.length > 0 ? (
        <Box
          width={600}
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
        >
          <PieChart
            data={data}
            reveal
            label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}
            labelStyle={{
              fontSize: '8px',
              fontColor: 'FFFFFA',
            }}
            viewBoxSize={[100, 100]}
          />
          <Box ml={5}>
            {data.map((item, i) => (
              <Box
                width={200}
                key={`legend-item-${i}`}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Box
                  mr={1}
                  style={{ backgroundColor: item.color, width: 60, height: 30 }}
                />
                <p style={{ fontSize: 14 }}>{item.title}</p>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <p>Nav atbilžu</p>
      )}
    </Box>
  )
}
