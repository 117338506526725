import React from 'react'
import HistoryBase from "./HistoryBase";
import {LIST_COMPANY_HISTORY} from "../../../gql/queries";
import {columns} from "../business/Companies";

const keyLabels = columns.reduce((labels, c) => {
    labels[c.field] = c.title;
    return labels;
}, {});

export default function CompanyHistory() {
    return <HistoryBase query={LIST_COMPANY_HISTORY} queryPath="companies" keyLabels={keyLabels} />
}