import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import BackIcon from '@material-ui/icons/KeyboardArrowLeft'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { ShowBurgerMenuButton } from '../views/menu/ShowBurgerMenuButton'
import CityLogoWhite from './icons/CityLogoWhite'

const useStyles = makeStyles(theme => ({
  appBar: {
    paddingTop: 'env(safe-area-inset-top)',
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: '#FFFFFF',
    backgroundColor: '#142449',
  },
  toolbar: {
    padding: 0,
  },
  icon: {
    width: 32,
    height: 32,
    color: '#FFFFFF',
    [theme.breakpoints.up('sm')]: {
      width: 50,
      height: 50,
    },
  },
  logo: {
    marginTop: '6px',
    marginRight: '10px',
    width: 120,
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: 180,
    },
  },
  main: {
    marginTop: 12,
  },
}))

export default function TopBar({ children, onBackClick }) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div>
      <AppBar position="sticky" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <IconButton
                onClick={() => (onBackClick ? onBackClick() : history.goBack())}
              >
                <BackIcon className={classes.icon} />
              </IconButton>
            </Grid>
            <Grid item className={classes.logo}>
              <CityLogoWhite />
            </Grid>
            <Grid item>
              <ShowBurgerMenuButton>
                <MenuIcon className={classes.icon} />
              </ShowBurgerMenuButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <main>{children}</main>
    </div>
  )
}
