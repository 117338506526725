import React from 'react'
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    logo: {
        fill: '#FFFFFF',
    },
}));

export default function CityLogoWhite() {
    const classes = useStyles();
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 443 93.19">
            <path className={classes.logo}
                  d="M376.67 43.49c-.83-5.09-3.79-8.14-8.6-9.25v-.19C374.64 32.29 379 26.46 379 19c0-12.31-7.86-19-22.66-19h-28v61.81h20.19V42.56h4.33c2.22 0 3.52 1.39 3.89 4.08l.77 8.39a22.8 22.8 0 00.54 4.11 14.4 14.4 0 00.89 2.67h21.53a15 15 0 01-1.89-5.59c-.53-3.23-1.86-12.73-1.86-12.73M353.63 27h-5.16V16.47h5.16c3 0 4.17 1.57 4.17 5.27S356.5 27 353.63 27M70.81 0l-17.4 61.81h19.8l2.22-9.72h14.44l2.31 9.72h20.54L95.33 0zm7.9 38.31l3.84-16.66h.2l3.94 16.66zM140.01 0h-20.73v61.81h41.14V44.88h-20.41V0zM198.79 20.73h-.28L190.09 0H168.9v61.81h19.53V46.36l-.18-13.14h.27l5.18 14.25h9.81l5.18-14.25h.28l-.18 13.14v15.45h19.52V0h-21l-8.52 20.73zM241.6 0h20.73v61.81H241.6zM295.16 38.31h18.88V23.13h-18.88v-6.29h21.47V0h-41.55v61.81h41.73V44.97h-21.65v-6.66zM425.61 0h-24.52l-17.4 61.81h19.8l2.22-9.72h14.44l2.31 9.72H443zM409 38.31l3.85-16.66h.15l4 16.66z"/>
            <path className={classes.logo} transform="rotate(-45 28.69 80.106)" d="M19.43 70.86h18.5v18.5h-18.5z"/>
            <path className={classes.logo} d="M39.83 61.81L57.37 0H35.9l-7.1 33.35h-.23L21.47 0H0l17.54 61.81h22.29z"/>
        </svg>
    );
}