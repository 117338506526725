import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CityLogoDark from "@mcity/valmiera-core/src/components/elements/icons/CityLogoDark";
import LoginForm from "@mcity/valmiera-core/src/components/elements/LoginForm";
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom'
import routes from '../../routes/routes'

const useStyles = makeStyles({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    logo: {
        paddingTop: '23px',
        width: '163px',
        height: '34px',
        margin: '0 auto',
    },
});

export default function SignIn() {
    const classes = useStyles();
    const history = useHistory();
    const [alert, setAlert] = useState(history?.location?.state?.alert);
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item>
                <div className={classes.paper}>
                    <div className={classes.logo} >
                        <CityLogoDark />
                    </div>
                    <LoginForm onSuccess={(user)=>{
                        if(user.isAdmin)
                            history.replace(routes.suggestions.list)
                        else
                            history.replace(routes.business.transactions)
                    }} />
                    <div>
                        {alert && <MuiAlert onClose={() => setAlert(null)} severity={alert ? alert.severity : 'info'}>{alert ? alert.message : ''}</MuiAlert>}
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}
