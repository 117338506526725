import React, {useState} from 'react'
import Box from '@material-ui/core/Box'
import {useBusinessAuth} from "@mcity/valmiera-core/src/components/providers/BusinessAuthProvider";
import CompanyName from "@mcity/valmiera-core/src/components/elements/CompanyName";
import ChangeCompanyUserPasswordTextField from "../../elements/ChangeCompanyUserPasswordTextField";
import CompanyUsers from "./CompanyUsers";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";

export default function Profile() {
    const { companyId, username } = useBusinessAuth();
    const [changePasswordUsername, setChangePasswordUsername] = useState(null);
    const setChangePasswordDialogOpen = open => {
        if(!open) {
            setChangePasswordUsername(null);
        }
    }

    return (
        <>
            {
                changePasswordUsername && <ChangeCompanyUserPasswordTextField
                    changePasswordUsername={changePasswordUsername}
                    setOpen={setChangePasswordDialogOpen} />
            }
            <Box width={500} mt={2} ml={2}>
                <Box mb={1}>
                    <Typography variant="h5">Pamatinformācija</Typography>
                </Box>
                <Typography variant="h6">Uzņēmums: <CompanyName companyId={companyId} /></Typography>
                <Typography variant="h6">Lietotājs: {username}</Typography>
                <Box width={200} mt={1} mb={3}>
                    <Button
                        fullWidth
                        onClick={() => setChangePasswordUsername(username)}
                        size="large"
                        variant="contained"
                        color="primary">
                        Nomainīt paroli
                    </Button>
                </Box>
                <Box mb={1}>
                    <Typography variant="h5">Atbildīgās personas</Typography>
                </Box>
                <CompanyUsers
                    companyId={companyId}
                    companyAdmins={false}
                />
            </Box>
        </>
    )
}
