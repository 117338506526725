import 'core-js/stable'
import './index.css';
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
// eslint-disable-next-line no-unused-vars
import {i18n} from '@mcity/valmiera-core'
import * as moment from 'moment';
moment.locale('lv');

const rootEl = document.getElementById('root')
ReactDOM.render(<App />, rootEl)
