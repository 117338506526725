import React from 'react'
import { useMutation } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextFieldEditorRenderer from '@mcity/core/lib/components/elements/TextFieldEditorRenderer'
import { UPDATE_SUGGESTION } from '@mcity/core/lib/gql/mutations'

export default function SuggestionResponsibleRenderer({
  id,
  responsible,
  onError,
  onSuccess,
}) {
  const [updateSuggestion, { loading }] = useMutation(UPDATE_SUGGESTION)
  const onChange = value => {
    updateSuggestion({
      variables: {
        id,
        input: {
          responsible: value,
        },
      },
    })
      .then(({ data }) => {
        if (data?.incidents?.update) {
          onSuccess({ message: `Atbildīgā persona nomainīta` })
        }
      })
      .catch(onError)
  }
  if (loading) {
    return <CircularProgress />
  }
  return <TextFieldEditorRenderer value={responsible} onChange={onChange} />
}
