import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import MuiAlert from '@material-ui/lab/Alert'
import DynamicItemList from '../DynamicItemList'
import SaveOrCancelDialog from '../SaveOrCancelDialog'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export const KIND_YES_OR_NO = 'YES_OR_NO'
export const KIND_TEXT = 'TEXT'
export const KIND_SINGLE_CHOICE_POLL = 'SINGLE_CHOICE_POLL'
export const KIND_MULTIPLE_CHOICE_POLL = 'MULTIPLE_CHOICE_POLL'

const questionKinds = [
  KIND_YES_OR_NO,
  KIND_TEXT,
  KIND_SINGLE_CHOICE_POLL,
  KIND_MULTIPLE_CHOICE_POLL,
]

export const questionKindLabels = {
  [KIND_YES_OR_NO]: 'Jā/Nē',
  [KIND_TEXT]: 'Teksts',
  [KIND_SINGLE_CHOICE_POLL]: 'Radiopogu saraksts',
  [KIND_MULTIPLE_CHOICE_POLL]: 'Vairākatlašu saraksts',
}

const REQUIRED_POLL_ANSWER_COUNT = 2

export default function SurveyQuestionEditor({
  questionNumber,
  question,
  setQuestion,
  complete,
  alert,
  loading,
  setOpen,
}) {
  const [errors, setErrors] = useState({})
  const [questionText, setQuestionText] = useState(question?.questionText || '')
  const [questionKind, setQuestionKind] = useState(question?.questionKind || '')
  const [responses, setResponses] = useState(question?.responses || [])
  const [required, setRequired] = useState(question?.required || false)

  function areResponsesRequired() {
    return (
      questionKind === KIND_SINGLE_CHOICE_POLL ||
      questionKind === KIND_MULTIPLE_CHOICE_POLL
    )
  }

  useEffect(() => {
    if (areResponsesRequired()) {
      if (!responses || responses.length < REQUIRED_POLL_ANSWER_COUNT) {
        setResponses(Array(REQUIRED_POLL_ANSWER_COUNT).fill(''))
      }
    } else {
      setResponses([])
    }
  }, [questionKind])

  function onRemoveResponse(i) {
    const updated = [...responses]
    updated.splice(i, 1)
    setResponses(updated)
  }

  function onAppendResponse() {
    setResponses([...responses, ''])
  }

  function onUpdateResponse(i, value) {
    const updated = [...responses]
    updated[i] = value
    setResponses(updated)
  }

  function onProceed() {
    if (invalidateQuestion()) {
      setQuestion({ questionText, questionKind, responses, required })
    }
  }

  function invalidateQuestion() {
    const errors = {}
    if (questionText.length < 1) {
      errors.questionText = 'Šīs lauks ir obligāts'
    }
    if (questionKind.length < 1) {
      errors.questionKind = 'Šīs lauks ir obligāts'
    }
    if (areResponsesRequired()) {
      if (responses.length < REQUIRED_POLL_ANSWER_COUNT) {
        errors.responses = `Lūdzu ievadiet vismaz ${REQUIRED_POLL_ANSWER_COUNT} atbildes`
      }
      responses.forEach((response, responseIndex) => {
        if (!response || response.length === 0) {
          errors[`response${responseIndex}`] = 'Šīs lauks ir obligāts'
        }
      })
    }
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  function onMovedResponse({ from, to }) {
    const result = Array.from(responses)
    const [removed] = result.splice(from, 1)
    result.splice(to, 0, removed)
    setResponses(result)
  }

  return (
    <SaveOrCancelDialog
      title={`${questionNumber}. jautājums`}
      onProceed={onProceed}
      setOpen={setOpen}
      complete={complete}
      alert={alert}
      loading={loading}
    >
      <Box style={{ width: '100%' }}>
        <Box display="flex" flexDirection="row" style={{ width: '100%' }}>
          <TextField
            label="Jautājuma teksts"
            variant="outlined"
            style={{ width: '60%', minWidth: 420, marginRight: '5px' }}
            value={questionText}
            error={errors.questionText}
            onChange={event => setQuestionText(event.target.value)}
          />
          <TextField
            label="Atbildes veids"
            style={{ width: '40%', minWidth: 220 }}
            variant="outlined"
            id="select"
            value={questionKind}
            select
            error={errors.questionKind}
            onChange={event => setQuestionKind(event.target.value)}
          >
            {questionKinds.map(kind => (
              <MenuItem key={kind} value={kind}>
                {questionKindLabels[kind]}
              </MenuItem>
            ))}
          </TextField>
          <FormControlLabel
            style={{ marginLeft: 1 }}
            control={
              <Checkbox
                color="primary"
                size="medium"
                checked={required}
                onChange={event => setRequired(event.target.checked)}
              />
            }
            label="Obligāts"
          />
        </Box>
        {errors.responses && (
          <Box mt={2}>
            <MuiAlert severity="warning">{errors.responses}</MuiAlert>
          </Box>
        )}
        {areResponsesRequired() && (
          <DynamicItemList
            onAppend={onAppendResponse}
            onRemove={i => onRemoveResponse(i)}
            onMoved={onMovedResponse}
          >
            {responses.map((response, responseIndex) => (
              <TextField
                key={`response-${responseIndex + 1}`}
                label={`${responseIndex + 1}. atbilde`}
                variant="outlined"
                value={response}
                fullWidth
                error={errors[`response${responseIndex}`]}
                onChange={event =>
                  onUpdateResponse(responseIndex, event.target.value)
                }
              >
                {response}
              </TextField>
            ))}
          </DynamicItemList>
        )}
      </Box>
    </SaveOrCancelDialog>
  )
}
