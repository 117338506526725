import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useValmieraConfig } from '@mcity/valmiera-core/src/components/providers/ValmieraConfigProvider'
import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import MaterialTable from '../../elements/MaterialTable'
import { useQuery } from '@apollo/client'
import Button from '@material-ui/core/Button'
import { GET_COMPANY_OFFERS, GET_OFFERS } from '../../../gql/queries'
import { useBusinessAuth } from '@mcity/valmiera-core/src/components/providers/BusinessAuthProvider'
import { format } from 'date-fns'
import AddEditOfferDialog from './AddEditOffer'
import { useSnackBar } from '@mcity/core/lib/components/providers/SnackBarProvider'
import { lv } from 'date-fns/locale'

const OfferOrderBy = {
  TX_ID: 'TX_ID',
}

export const columns = [
  { field: 'id', title: 'Piedāvājuma ID', sortId: OfferOrderBy.TX_ID },
  { field: 'status', title: 'Statuss' },
  { field: 'title', title: 'Nosaukums' },
  { field: 'description', title: 'Apraksts' },
  {
    field: 'attachment',
    title: 'Titulbilde',
    render: rowData =>
      rowData.attachment !== '' ? (
        <a href={rowData.attachment} download>
          Lejupielādēt
        </a>
      ) : (
        ''
      ),
  },
  { field: 'discount', title: 'Atlaide (%)' },
  {
    field: 'authenticatedDiscount',
    title: 'Autentificēta lietotāja atlaide (%)',
  },
  { field: 'declaredDiscount', title: 'Pašvaldības iedzīvotāja atlaide (%)' },
  { field: 'silverDiscount', title: 'Sudraba atlaide (%)' },
  { field: 'goldDiscount', title: 'Zelta atlaide (%)' },
  { field: 'isGift', title: 'Dāvana' },
  { field: 'dateTime', title: 'Aktuāls līdz' },
]

const useStyles = makeStyles(theme => ({
  search: {
    maxWidth: 200,
  },
  searchIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  date: {
    maxWidth: 200,
  },
  autocomplete: {
    width: 200,
  },
}))

export default function Offers() {
  const { showSnackBar } = useSnackBar()
  const classes = useStyles()
  const config = useValmieraConfig()
  const [searchText, setSearchText] = React.useState('')
  const [rows, setRows] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [sortedBy, setSortedBy] = React.useState(OfferOrderBy.TX_ID)
  const [orderDesc, setOrderDesc] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [offerIdToEdit, setOfferIdToEdit] = React.useState(null)
  const [showAddEditOfferDialog, setShowAddEditOfferDialog] = React.useState(
    false,
  )
  const [totalCount, setTotalCount] = React.useState(0)
  const { isAdmin, isCompanyAdmin, companyId } = useBusinessAuth()

  const reshapedOffers = ({ expiry, isGift, attachment, ...offers }) => {
    return {
      dateTime: format(new Date(expiry), 'P p', { locale: lv }),
      isGift: isGift ? 'Jā' : 'Nē',
      attachment: attachment
        ? `${config.downloadUrl}?file_id=${attachment.id}&token=${attachment.token}`
        : '',
      ...offers,
    }
  }

  const { loading, refetch } = useQuery(
    isAdmin ? GET_OFFERS : GET_COMPANY_OFFERS,
    {
      variables: isAdmin
        ? {
            skip: page * rowsPerPage,
            limit: rowsPerPage,
          }
        : {
            filter: {
              companyId: companyId,
              skip: page * rowsPerPage,
              limit: rowsPerPage,
            },
          },
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        if (isAdmin && data.offers.offers.list) {
          setRows(data.offers.offers.list.map(reshapedOffers))
          setTotalCount(data.offers.offers.totalCount)
        } else if (!isAdmin && data.offers.companyOffers.list) {
          setRows(data.offers.companyOffers.list.map(reshapedOffers))
          setTotalCount(data.offers.companyOffers.totalCount)
        }
      },
    },
  )

  return (
    <>
      {showAddEditOfferDialog && (
        <AddEditOfferDialog
          offerId={offerIdToEdit}
          onClose={() => {
            setOfferIdToEdit(null)
            setShowAddEditOfferDialog(false)
          }}
          onSuccess={() => {
            showSnackBar()
            setShowAddEditOfferDialog(false)
            setOfferIdToEdit(null)
            refetch()
          }}
        />
      )}
      <Box
        px={2}
        mt={1}
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <TextField
          variant="standard"
          label="Search..."
          className={classes.search}
          value={searchText}
          onChange={({ target: { value } }) => setSearchText(value)}
          InputProps={{
            endAdornment: (
              <IconButton disabled>
                <InputAdornment position="end">
                  <SearchIcon onClick={null} />
                </InputAdornment>
              </IconButton>
            ),
          }}
        />
        {isCompanyAdmin ? (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setShowAddEditOfferDialog(true)
            }}
          >
            Jauns piedāvājums
          </Button>
        ) : (
          <></>
        )}
      </Box>
      <MaterialTable
        loading={loading}
        columns={columns}
        data={rows}
        components={{
          Pagination: props => (
            <TablePagination
              {...props}
              count={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={event => {
                setRowsPerPage(Number(event.target.value))
                props.onChangeRowsPerPage(event)
              }}
              onChangePage={(_, page) => setPage(page)}
            />
          ),
          Header: data => (
            <TableHead>
              <TableRow>
                {data.columns.map(col => (
                  <TableCell key={col.field}>
                    {col.sortId ? (
                      <TableSortLabel
                        active={col.sortId === sortedBy}
                        direction={orderDesc ? 'desc' : 'asc'}
                        onClick={() => {
                          if (col.sortId === sortedBy) setOrderDesc(!orderDesc)
                          else setOrderDesc(false)

                          setSortedBy(col.sortId)
                        }}
                      >
                        {col.title}
                      </TableSortLabel>
                    ) : (
                      col.title
                    )}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
          ),
        }}
        options={{
          pageSize: rowsPerPage,
        }}
        actions={[
          {
            icon: 'edit',
            onClick: (_, row) => {
              setOfferIdToEdit(row.id)
              setShowAddEditOfferDialog(true)
            },
          },
        ]}
      />
    </>
  )
}
