import { gql } from '@apollo/client'

// TODO: we should make 'limit' param optional
export const GET_TOTAL_USERS_BY_STATUS = gql`
    query GetTotalUsersByStatus($status: UserStatus){
        users {
            users(lookup:{
                skip: 0
                limit: 100000
                filter:{
                    authMethod: LATVIALV
                    status:  $status
                }
            }) {
                count
            }
        }
    }
`

export const GET_VALMIERA_ATTACHMENT = gql`
    query GetValmieraAttachment($id: Long!) {
        attachments {
            attachment(id: $id) {
                id
                token
            }
        }
    }
`

export const GET_VALMIERA_INCIDENT = gql`
    query GetIncident($id: Long!) {
        incidents {
            incident(id: $id) {
                id
                title
                description
                lat
                lng
                responseComment
                responseStatus
                responseDate
                contactInfo
                address
                creationDate
                status
                attachments {
                    id
                    token
                }
            }
        }
    }
`

export const GET_VALMIERA_INCIDENTS = gql`
    query GetIncidents($limit: Int!, $skip: Int!, $filter: ListFilter) {
        incidents {
            incidents(
                lookup: {
                    limit: $limit
                    skip: $skip
                    orderAscending: false
                    orderBy: "id"
                    filter: $filter
                }
            ) {
                id
                description
                lat
                lng
                address
                status
                comments
                responsible
                plannedDueDate
                userId
                creationDate
                attachments {
                    id
                    token
                }
            }
        }
    }
`