import React, {useState} from 'react';
import L from 'leaflet';
import {Map, TileLayer, Marker, Popup} from "react-leaflet";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit';
import MarkerClusterGroup from "react-leaflet-markercluster";
import {statusLabels} from "./SuggestionStatusRenderer";
import Paper from "@material-ui/core/Paper";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import SuggestionEdit from "./SuggestionEdit";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {suggestionTableColumns} from "./SuggestionsTable";
import Table from "@material-ui/core/Table";
import {useHistory} from "react-router-dom";

import flagGreyImage from './flags/flag-grey.png';
import flagBlueImage from './flags/flag-blue.png';
import flagGreenImage from './flags/flag-green.png';
import flagYellowImage from './flags/flag-yellow.png';
import {useValmieraConfig} from "@mcity/valmiera-core/src/components/providers/ValmieraConfigProvider";

const ICON_DIM = 96;
const ICON_ANCHOR_X = 20;
const ICON_ANCHOR_Y = 86;
const ICON_SCALE = 0.4;

const ICON_SIZE = [ICON_DIM * ICON_SCALE, ICON_DIM * ICON_SCALE]; // size of the icon
const ICON_ANCHOR = [ICON_ANCHOR_X * ICON_SCALE, ICON_ANCHOR_Y * ICON_SCALE]; // point of the icon which will correspond to marker's location
const POPUP_ANCHOR = [ICON_ANCHOR_X * ICON_SCALE, -ICON_ANCHOR_Y * ICON_SCALE]; // point from which the popup should open relative to the iconAnchor

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
    },
    popup: {
        fontSize: '12px',
        display: 'flex',
        flexDirection: 'column',
    },
    map: {
        width: '100%',
        height: '100vh',
    },
}));

const createFlag = image => new L.Icon({
    iconUrl: image,
    iconRetinaUrl: image,
    iconSize: ICON_SIZE,
    iconAnchor: ICON_ANCHOR,
    popupAnchor:  POPUP_ANCHOR,
});
const flagGrey = createFlag(flagGreyImage);
const flagBlue = createFlag(flagBlueImage);
const flagGreen = createFlag(flagGreenImage);
const flagYellow = createFlag(flagYellowImage);

const statusIcons = {
    NEW: flagGrey,
    STARTED: flagBlue,
    DONE: flagGreen,
    PLANNED: flagYellow,
};

export default function SuggestionsMap({suggestions}) {
    const {location} = useHistory();
    const query = new URLSearchParams(location.search);
    const hasCoordinates = query.has('lat') && query.has('lng');
    const { cityCenter } = useValmieraConfig();
    const center = hasCoordinates
        ? [query.get('lat'), query.get('lng')]
        : cityCenter;

    const classes = useStyles();
    const [editSuggestion, setEditSuggestion] = React.useState(null);
    const [alert, setAlert] = useState(null);
    const onError = ({message}) => {
        setAlert({severity: 'error', message: message});
    }
    const onSuccess = ({message}) => {
        setAlert({severity: 'success', message});
    }
    const onCloseAlert = () => {
        setAlert(null);
    }
    const onDialogClose = () => {
        setEditSuggestion(null);
    };
    return (
        <Paper className={classes.root}>
            <Snackbar open={alert !== null} autoHideDuration={6000} onClose={onCloseAlert}>
                <MuiAlert onClose={onCloseAlert} severity={alert ? alert.severity : 'info'}>{alert ? alert.message : ''}</MuiAlert>
            </Snackbar>
            <Dialog open={editSuggestion !== null}
                    onClose={onDialogClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth='xl'>
                <DialogTitle id="form-dialog-title">Iesnieguma labošana</DialogTitle>
                {editSuggestion && <DialogContent>
                    <Table stickyHeader aria-label="simple table">
                        <colgroup>
                            <col style={{width:'1%', minWidth: '70px'}}/>
                            <col style={{width:'20%'}}/>
                            <col style={{width:'10%'}}/>
                            <col style={{width:'10%'}}/>
                            <col style={{width:'5%'}}/>
                            <col style={{width:'20%'}}/>
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                {suggestionTableColumns
                                    .map(({label}) => (
                                    <TableCell>
                                        {label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <SuggestionEdit suggestion={editSuggestion} onSuccess={onSuccess} onError={onError} />
                    </Table>
                </DialogContent>}
                <DialogActions>
                    <Button onClick={onDialogClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Map center={center} zoom={hasCoordinates ? 18 : 13} className={classes.map}>
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MarkerClusterGroup>
                    {
                        suggestions.map(s => (
                            <Marker position={[s.lat, s.lng]} icon={statusIcons[s.status]}>
                                <Popup>
                                    <div className={classes.popup}>
                                        <Typography variant="h6">
                                            {statusLabels[s.status]}
                                        </Typography>
                                        <Typography variant="body1" component="p">
                                            {s.description}
                                        </Typography>
                                        <Button color="primary" style={{alignSelf: 'flex-end'}} startIcon={<EditIcon />} onClick={() => setEditSuggestion(s)}>
                                            LABOT
                                        </Button>
                                    </div>
                                </Popup>
                            </Marker>
                        ))
                    }
                </MarkerClusterGroup>
            </Map>
        </Paper>
    )
}