import { gql } from '@apollo/client'

export const GET_OFFER = gql`
  query GetOffer($id: Long!) {
    offers {
      offer(id: $id) {
        id
        companyId
        title
        description
        attachmentId
        attachment {
          id
          name
          dateAdded
          fileType
          token
          isUploaded
        }
        isGift
        discount
        authenticatedDiscount
        declaredDiscount
        silverDiscount
        goldDiscount
        expiry
        status
      }
    }
  }
`

export const GET_COMPANY_OFFERS = gql`
  query GetCompanyOffers($filter: CompanyOfferFilter) {
    offers {
      companyOffers(filter: $filter) {
        list {
          id
          status
          companyId
          title
          description
          attachmentId
          attachment {
            id
            name
            dateAdded
            fileType
            token
            isUploaded
          }
          isGift
          discount
          authenticatedDiscount
          declaredDiscount
          silverDiscount
          goldDiscount
          expiry
          status
        }
        count
        totalCount
      }
    }
  }
`

export const GET_OFFERS = gql`
  query GetOffers($limit: Int!, $skip: Int!) {
    offers {
      offers(
        lookup: {
          limit: $limit
          skip: $skip
          orderBy: "id"
          orderAscending: false
        }
      ) {
        list {
          id
          status
          companyId
          title
          description
          attachment {
            id
            name
            dateAdded
            fileType
            token
            isUploaded
          }
          attachmentId
          isGift
          discount
          authenticatedDiscount
          declaredDiscount
          silverDiscount
          goldDiscount
          expiry
        }
        count
        totalCount
      }
    }
  }
`
export const GET_TRANSACTIONS = gql`
  query GetTransactions($input: CompanyTransactionFilter!) {
    transactions {
      CompanyTransactions(input: $input) {
        totalCount
        list {
          id
          companyId
          userId
          offerId
          sum
          discountType
          discountAmount
          description
          userLoyalty
          time
        }
      }
    }
  }
`

export const GET_COMPANIES = gql`
  query GetCompanies($filter: CompaniesFilter!) {
    companies {
      companies(filter: $filter) {
        totalCount
        list {
          id
          name
          regNo
          status
          phone
        }
      }
    }
  }
`
export const GET_COMPANY = gql`
  query GetCompany($id: Long!) {
    companies {
      company(id: $id) {
        id
        name
        regNo
        phone
        status
      }
    }
  }
`

export const GET_COMPANY_USERS = gql`
  query GetCompanyUsers($companyId: Long) {
    users {
      users(lookup: { 
        skip: 0, 
        limit: 100,
        filter: {
          companyId: $companyId,
        }
      }) {
        list {
          id
          username
          params {
            key
            value
          }
        }
      }
    }
  }
`

export const GET_LATVIJA_LV_USERS = gql`
    query GetLatvijaLVUsers($limit: Int!, $skip: Int!, $filter: UserFilterInput){
        users {
            users(lookup:{
                limit: $limit
                skip: $skip
                filter: $filter
            }) {
                list {
                    id
                    username
                    status
                    params {
                        key
                        value
                    }
                }
                totalCount
            }
        }
    }
`

export const GET_TOTAL_USERS_BY_LOYALTY = gql`
    query GetTotalUsersByLoyalty($loyalty: LoyaltyLevels){
        users {
            users(lookup:{
                skip: 0
                limit:100000
                filter:{
                    loyalty: $loyalty
                }
            }) {
                count
            }
        }
    }
`

export const GET_TOTAL_GUESTS = gql`
    query GetTotalGuests{
        guests {
            count
        }
    }
`

export const GET_SURVEYS = gql`
  query GetSurveys($limit: Int!, $skip: Int!) {
    surveys {
      surveys(
        lookup: {
          limit: $limit
          skip: $skip
          orderBy: "id"
          orderAscending: false
        }
      ) {
        id
        startOn
        endOn
        parameters {
          key
          value
        }
        questions {
          baseId
          kind
          texts {
            lang
            text
          }
          answers {
            id
            texts {
              lang
              text
            }
          }
        }
      }
    }
  }
`

export const GET_RESPONSES = gql`
  query GetResponses($surveyId: Long!) {
    responses {
      responses(surveyId: $surveyId) {
        survey {
          id
          startOn
          endOn
          permitLevel
          parameters {
            key
            value
          }
          questions {
            baseId
            kind
            required
            texts {
              lang
              text
            }
            answers {
              id
              texts {
                lang
                text
              }
            }
          }
        }
        responses {
          baseId
          userId
          questionId
          answerId
          freeAnswer
          responseTime
        }
      }
    }
  }
`
// TODO: move LIST_EVENTS query to @core
export const LIST_EVENTS = gql`
  query ListEvents($limit: Int!, $skip: Int!) {
    events {
      complexEvents(
        lookup: {
          limit: $limit
          skip: $skip
          orderBy: "start_time"
          orderAscending: false
        }
      ) {
        list {
          id
          img
          title
          category
          regions
          startTime
          endTime
          intro
          description
          interestedCount
          interestEnabled
          autoImported
        }
        count
        totalCount
      }
    }
  }
`

export const GET_EVENT = gql`
  query GetEvent($id: Long) {
    events {
      event(id: $id) {
        id
        img
        title
        category
        regions
        startTime
        endTime
        intro
        description
        interestedCount
        autoImported
        interestEnabled
      }
    }
  }
`

export const LIST_COMPANY_HISTORY = gql`
  query ListCompanyHistory($limit: Int!, $skip: Int!){
    companies {
      history(
        lookup: {
          limit: $limit
          skip: $skip
          orderBy: "id"
          orderAscending: false
        }
      ) {
        list {
          kind
          audit {
            userId
            ipAddress
          }
          previousEntityId
          currentEntityId
          differences {
            key
            previousValue
            currentValue
          }
        }
        count
        totalCount
      }
    }
  }
`

export const LIST_OFFER_HISTORY = gql`
  query ListOfferHistory($limit: Int!, $skip: Int!){
    offers {
      history(
        lookup: {
          limit: $limit
          skip: $skip
          orderBy: "id"
          orderAscending: false
        }
      ) {
        list {
          kind
          audit {
            userId
            ipAddress
          }
          previousEntityId
          currentEntityId
          differences {
            key
            previousValue
            currentValue
          }
        }
        count
        totalCount
      }
    }
  }
`

export const LIST_TRANSACTION_HISTORY = gql`
  query ListTransactionHistory($limit: Int!, $skip: Int!){
    transactions {
      history(
        lookup: {
          limit: $limit
          skip: $skip
          orderBy: "id"
          orderAscending: false
        }
      ) {
        list {
          kind
          audit {
            userId
            ipAddress
          }
          previousEntityId
          currentEntityId
          differences {
            key
            previousValue
            currentValue
          }
        }
        count
        totalCount
      }
    }
  }
`

export const LIST_EVENT_HISTORY = gql`
  query ListEventHistory($limit: Int!, $skip: Int!){
    events {
      history(
        lookup: {
          limit: $limit
          skip: $skip
          orderBy: "id"
          orderAscending: false
        }
      ) {
        list {
          kind
          audit {
            userId
            ipAddress
          }
          previousEntityId
          currentEntityId
          differences {
            key
            previousValue
            currentValue
          }
        }
        count
        totalCount
      }
    }
  }
`

export const LIST_SURVEY_HISTORY = gql`
  query ListSurveyHistory($limit: Int!, $skip: Int!){
    surveys {
      history(
        lookup: {
          limit: $limit
          skip: $skip
          orderBy: "id"
          orderAscending: false
        }
      ) {
        list {
          kind
          audit {
            userId
            ipAddress
          }
          previousEntityId
          currentEntityId
          differences {
            key
            previousValue
            currentValue
          }
        }
        count
        totalCount
      }
    }
  }
`
