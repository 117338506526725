import React from 'react'
import config from '../../config'

const Context = React.createContext(null)

export function useValmieraConfig() {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error('useValmieraConfig must be used within a ValmieraConfigProvider')
  }
  return context
}

export function withConfig(WrappedComponent) {
  return props => (
    <Context.Consumer>
      {value => <WrappedComponent {...props} config={value} />}
    </Context.Consumer>
  )
}

export default function ValmieraConfigProvider({ value, children }) {
  return <Context.Provider value={value || config}>{children}</Context.Provider>
}
