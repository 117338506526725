import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import { useValmieraConfig } from '@mcity/valmiera-core/src/components/providers/ValmieraConfigProvider'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const useStyles = makeStyles({
  carousel: {
    '& ul': {
      padding: 0,
    },
  },
})

export default function PicturesDialog({ attachments, onClose }) {
  const classes = useStyles()
  const config = useValmieraConfig()

  return (
    <Dialog open onClose={onClose}>
      {attachments.length > 0 && (
        <Carousel
          className={classes.carousel}
          dynamicHeight
          showStatus={false}
          showThumbs={false}
        >
          {attachments?.map((x, i) => (
            <div key={i}>
              <img
                src={`${config.downloadUrl}?file_id=${x.id}&token=${x.token}`}
                alt="suggestion"
              />
            </div>
          ))}
        </Carousel>
      )}
    </Dialog>
  )
}
