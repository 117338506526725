export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export function parseImageSource(img) {
  const parser = new DOMParser()
  const html = parser.parseFromString(img, 'text/html')
  return html?.images?.[0]?.src || img
}
