import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import { format } from 'date-fns'
import { lv } from 'date-fns/locale'
import { KIND_TEXT, questionKindLabels } from './SurveyQuestionEditor'
import { Link } from 'react-router-dom'
import { reverse } from 'named-urls/src'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import routes from '../../../routes/routes'
import { permitLevelCompareWeights, permitLevelLabels } from './SurveyEditor'
import TableChartIcon from '@material-ui/icons/TableChart'
import PieChartIcon from '@material-ui/icons/PieChart'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { truncate } from '@mcity/core/lib/utils/miscUtils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  alerts: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  description: {
    maxWidth: '1200px',
  },
  small: {
    maxWidth: '200px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

export const surveysTableColumns = [
  { id: 'title', label: 'Nosaukums', sortable: false },
  { id: 'description', label: 'Apraksts', sortable: false },
  { id: 'startOn', label: 'Sākums', sortable: true },
  { id: 'endOn', label: 'Beigas', sortable: true },
  { id: 'permitLevel', label: 'Pieejamība', sortable: true },
  { id: 'responses', label: 'Atbildes', sortable: false },
  { id: 'charts', label: 'Grafiki', sortable: false },
  { id: 'actions', label: '', sortable: false },
]

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

function SurveysTableRow({ survey, children }) {
  const [open, setOpen] = React.useState(false)
  const classes = useRowStyles()
  return (
    <React.Fragment key={survey.id}>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {survey.parameters.find(p => p.key === 'title').value}
        </TableCell>
        <TableCell>
          {truncate(
            survey.parameters.find(p => p.key === 'description').value,
            600,
          )}
        </TableCell>
        <TableCell>
          {format(new Date(survey.startOn), 'P', { locale: lv })}
        </TableCell>
        <TableCell>
          {format(new Date(survey.endOn), 'P', { locale: lv })}
        </TableCell>
        <TableCell>{permitLevelLabels[survey.permitLevel]}</TableCell>
        {children}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Jautājums</TableCell>
                    <TableCell>Atbildes veids</TableCell>
                    <TableCell>Obligāts</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {survey.questions.map(question => (
                    <TableRow key={`question-${question.id}`}>
                      <TableCell>{question.texts[0].text}</TableCell>
                      <TableCell component="th" scope="row">
                        {questionKindLabels[question.kind]}
                      </TableCell>
                      <TableCell>{question.required ? 'Jā' : 'Nē'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default function SurveysTable({ surveys, onDelete }) {
  const classes = useStyles()
  const [alert, setAlert] = useState(null)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('creationDate')

  const onError = ({ message }) => {
    setAlert({ severity: 'error', message: message })
  }
  const onSuccess = ({ message }) => {
    setAlert({ severity: 'success', message })
  }
  const onCloseAlert = () => {
    setAlert(null)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const stringComparator = orderBy => (a, b) => {
    return `${a[orderBy]}`.localeCompare(b[orderBy])
  }

  const dateComparator = orderBy => (a, b) => {
    return new Date(b[orderBy]) - new Date(a[orderBy])
  }

  const permitLevelComparator = (a, b) => {
    const wa = permitLevelCompareWeights[a.permitLevel]
    const wb = permitLevelCompareWeights[b.permitLevel]
    if (wb < wa) {
      return -1
    }
    if (wb > wa) {
      return 1
    }
    return 0
  }

  function getComparator(orderBy) {
    switch (orderBy) {
      case 'startOn':
      case 'endOn':
        return dateComparator(orderBy)
      case 'permitLevel':
        return permitLevelComparator
      default:
        return stringComparator(orderBy)
    }
  }

  function getOrderComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => getComparator(orderBy)(a, b)
      : (a, b) => -getComparator(orderBy)(a, b)
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }
  return (
    <Paper className={classes.root}>
      <Snackbar
        open={alert !== null}
        autoHideDuration={6000}
        onClose={onCloseAlert}
      >
        <MuiAlert
          onClose={onCloseAlert}
          severity={alert ? alert.severity : 'info'}
        >
          {alert ? alert.message : ''}
        </MuiAlert>
      </Snackbar>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <colgroup>
            <col style={{ width: '1%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '70%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '5%' }} />
            <col style={{ width: '1%' }} />
            <col style={{ width: '1%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell />
              {surveysTableColumns.map(({ id, label, sortable }) => (
                <TableCell key={id}>
                  {sortable ? (
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? order : 'asc'}
                      onClick={() => handleRequestSort(id)}
                    >
                      {label}
                      {orderBy === id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <p>{label}</p>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(surveys, getOrderComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(survey => (
                <SurveysTableRow key={survey.id} survey={survey}>
                  <TableCell>
                    <Link
                      to={reverse(`${routes.surveys.responses.view}`, {
                        id: survey.id,
                      })}
                    >
                      <IconButton>
                        <QuestionAnswerIcon />
                      </IconButton>
                    </Link>
                  </TableCell>
                  <TableCell>
                    {survey.questions.filter(({ kind }) => kind !== KIND_TEXT)
                      .length > 0 && (
                      <Link
                        to={reverse(`${routes.surveys.charts.view}`, {
                          id: survey.id,
                        })}
                      >
                        <IconButton>
                          <PieChartIcon />
                        </IconButton>
                      </Link>
                    )}
                  </TableCell>
                  {/* actions */}
                  <TableCell>
                    <IconButton onClick={() => onDelete(survey.id)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                </SurveysTableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 8, 10, 12, 20]}
          component="div"
          count={surveys.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Rindas lapā"
        />
      </TableContainer>
    </Paper>
  )
}
