import { useMutation } from '@apollo/client'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SEND_NOTIFICATION } from '../../gql/mutations'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import SendIcon from '@material-ui/icons/Send'
import { useSnackBar } from '@mcity/core/lib/components/providers/SnackBarProvider'
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 400,
    margin: '0 auto',
    marginTop: 32,
  },
  header: {
    fontSize: theme.typography.pxToRem(26),
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}))

export const SEND_NOTIF_TO = [
  {
    label: 'Visi',
    value: 'ALL',
  },
  {
    label: 'Reģistrēti',
    value: 'REGISTERED',
  },
  {
    label: 'Nereģistrēti',
    value: 'UNREGISTERED',
  },
]

const initialState = {
  title: '',
  body: '',
  link: '',
  data: [],
  sendNotificationsTo: SEND_NOTIF_TO[0].value,
}
const defaultErrorMsg = 'Lauks ir obligāti jāaizpilda.'
export default function PushNotifications() {
  const classes = useStyles()
  const [formState, setFormState] = React.useState(initialState)
  const [formErrors, setFormErrors] = React.useState({})
  const [sendNotification] = useMutation(SEND_NOTIFICATION)
  const { showSnackBar } = useSnackBar()
  const isFormValid = () => {
    const _errors = []
    if (!formState.title) _errors.title = defaultErrorMsg
    if (!formState.body) _errors.body = defaultErrorMsg

    setFormErrors(_errors)
    return Object.keys(_errors).length === 0
  }

  const onFieldChange = ({ target }) =>
    setFormState(_formState => ({ ..._formState, [target.name]: target.value }))

  const onSubmit = () => {
    if (!isFormValid()) return

    const input = {
      ...formState,
      data: formState.link ? [{ key: 'link', value: formState.link }] : [],
    }
    sendNotification({
      variables: {
        input,
      },
    })
      .then(response => {
        if (response?.data?.messages?.sendNotification?.id) {
          showSnackBar()
          setFormState(initialState)
        }
      })
      .catch(err => {
        console.error(err)
        showSnackBar({
          severity: 'error',
          text: `kļūda! ${JSON.stringify(err)}`,
        })
      })
  }
  return (
    <div className={classes.root}>
      <div className={classes.header}>Push paziņojumi</div>
      <TextField
        variant="outlined"
        margin="normal"
        size="small"
        label="Virsraksts"
        fullWidth
        name="title"
        autoComplete="off"
        value={formState.title}
        onChange={onFieldChange}
        helperText={formErrors.title}
        error={Boolean(formErrors.title)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        size="small"
        label="Ziņa"
        fullWidth
        multiline
        rows={3}
        name="body"
        autoComplete="off"
        inputProps={{ maxLength: 130 }}
        value={formState.body}
        onChange={onFieldChange}
        helperText={formErrors.body}
        error={Boolean(formErrors.body)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        size="small"
        label="Saite"
        fullWidth
        name="link"
        autoComplete="off"
        value={formState.link}
        onChange={onFieldChange}
        placeholder="https://www.example.com/article/123"
        helperText={formErrors.link}
        error={Boolean(formErrors.link)}
      />
      <FormControl margin="normal" variant="outlined" fullWidth size="small">
        <InputLabel>Nosūtīt</InputLabel>
        <Select
          value={formState.sendNotificationsTo}
          onChange={event => {
            setFormState(_formState => ({
              ..._formState,
              sendNotificationsTo: event.target.value,
            }))
          }}
          labelWidth={50}
        >
          {Object.values(SEND_NOTIF_TO).map(x => (
            <MenuItem key={x.value} value={x.value}>
              {x.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box mt={2} width="100%">
        <Button
          className={classes.button}
          variant="contained"
          onClick={onSubmit}
          fullWidth
          color="primary"
          startIcon={<SendIcon />}
        >
          Sūtīt
        </Button>
      </Box>
    </div>
  )
}
