import { gql } from '@apollo/client'

export const SET_SHOW_BURGER_MENU = gql`
  mutation SetShowBurgerMenu($show: Boolean!) {
    setShowBurgerMenu(show: $show) @client
  }
`

export const SET_USER_EVENT_INTERESTED = gql`
  mutation SetUserEventInterested($eventId: Int!, $isInterested: Boolean!) {
    setEventInterested(id: $eventId, isInterested: $isInterested) @client
  }
`

export const SAVE_SURVEY_RESPONSES = gql`
  mutation SaveSurveyResponses($input: [ResponseInput!]!) {
    responses {
      addResponse(input: $input)
    }
  }
`

export const SEND_REGISTRATION_EMAIL = gql`
  mutation SendRegistrationEmail($input: NewCompanyMailInput!) {
    companies {
      sendRegistrationEmail(input: $input)
    }
  }
`

export const REGISTER_PUSH_NOTIFICATION_RECIPIENT = gql`
  mutation RegisterPushNotificationRecipient($token: String!) {
    pushRecipients {
      addPushRecipient(token: $token) {
        userId
        token
      }
    }
  }
`

export const REGISTER_GUEST_USER = gql`
 mutation RegisterGuestUser($id: Long!) {
   guests {
     register(id: $id)
   }
 }
`