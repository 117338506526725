import { useMutation, useQuery } from '@apollo/client'
import { Error } from '@mcity/valmiera-app/src/components/elements'
import React, { useState } from 'react'
import { LIST_EVENTS } from '../../gql/queries'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Box from '@material-ui/core/Box'
import EventsTable from '../elements/events/EventsTable'
import EventCreateDialog from '../elements/events/EventCreateDialog'
import { DELETE_EVENT } from '../../gql/mutations'
import ConfirmDialog from '../elements/ConfirmDialog'
import { useModal } from 'react-modal-hook'
import { useSnackBar } from '@mcity/core/lib/components/providers/SnackBarProvider'

export default function Events() {
  const { showSnackBar } = useSnackBar()
  const [showCreateEvent, setShowCreateEvent] = useState(false)
  const [selectedEventIdToDelete, setSelectedEventIdToDelete] = useState(null)
  const { loading, error, data, refetch } = useQuery(LIST_EVENTS, {
    fetchPolicy: 'network-only',
    variables: {
      limit: 10,
      skip: 0,
    },
  })
  const [selectedEventId, setSelectedEventId] = React.useState(null)
  const [deleteSurvey] = useMutation(DELETE_EVENT)
  const [showConfirmDialog, hideConfirmDialog] = useModal(() => {
    const hideDialog = () => {
      setSelectedEventId(null)
      hideConfirmDialog()
    }

    return (
      selectedEventIdToDelete && (
        <ConfirmDialog
          text="Vai tiešām vēlaties dzēst šo pasākumu?"
          onClose={hideDialog}
          onAccept={() => {
            deleteSurvey({
              variables: {
                id: selectedEventIdToDelete,
              },
            })
              .then(response => {
                if (response?.data?.events?.delete) {
                  refetch()
                  showSnackBar()
                }
              })
              .catch(err =>
                showSnackBar({
                  severity: 'error',
                  text: `kļūda! ${JSON.stringify(err)}`,
                }),
              )
              .finally(() => {
                hideDialog()
              })
          }}
          onCancel={hideDialog}
        />
      )
    )
  }, [selectedEventIdToDelete])

  // if (loading) {
  //   return <CircularProgress />
  // }
  if (error) {
    return <Error>{error.message}</Error>
  }
  // if (!data?.events?.complexEvents) {
  //   return <Error>No data to display</Error>
  // }

  return (
    <>
      {showCreateEvent && (
        <EventCreateDialog
          eventId={selectedEventId}
          onComplete={() => {
            refetch()
          }}
          setOpen={setShowCreateEvent}
        />
      )}
      <Box
        px={2}
        mt={1}
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setSelectedEventId(null)
            setShowCreateEvent(true)
          }}
        >
          Pievienot
        </Button>
      </Box>
      <EventsTable
        setSelectedEventId={eventId => {
          setSelectedEventId(eventId)
          setShowCreateEvent(true)
        }}
        onDelete={eventId => {
          setSelectedEventIdToDelete(eventId)
          showConfirmDialog()
        }}
        refetch={refetch}
        data={data?.events?.complexEvents || []}
      />
    </>
  )
}
