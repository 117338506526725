import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { CREATE_SURVEY } from '../../gql/mutations'
import SurveyEditor from './surveys/SurveyEditor'
import { KIND_YES_OR_NO } from './surveys/SurveyQuestionEditor'

export default function SurveyCreateDialog({ setOpen, onComplete }) {
  const [createSurvey, { loading }] = useMutation(CREATE_SURVEY)
  const [alert, setAlert] = useState(null)
  const [complete, setComplete] = useState(false)

  const onError = ({ message }) => {
    setAlert({ severity: 'error', message: message })
  }
  const onSuccess = ({ message }) => {
    setAlert({ severity: 'success', message })
  }

  function prepareTexts(value) {
    return [
      {
        lang: 'lv',
        text: value,
      },
    ]
  }

  function convertResponsesToAnswers(responses) {
    return responses
      ? responses.map(response => ({ texts: prepareTexts(response) }))
      : undefined
  }

  function prepareAnswers({ questionKind, responses }) {
    if (questionKind === KIND_YES_OR_NO) {
      return convertResponsesToAnswers(['Jā', 'Nē'])
    }
    return convertResponsesToAnswers(responses)
  }

  const onSetSurvey = survey => {
    setAlert(null)
    const input = {
      startOn: survey.startOn,
      endOn: survey.endOn,
      permitLevel: survey.permitLevel,
      parameters: [
        { key: 'title', value: survey.title },
        { key: 'description', value: survey.description },
      ],
      questions: survey.questions.map(
        ({ questionText, questionKind, required, responses }) => ({
          texts: prepareTexts(questionText),
          kind: questionKind,
          answers: prepareAnswers({ questionKind, responses }),
          required: required,
        }),
      ),
    }
    createSurvey({ variables: { input } })
      .then(({ data }) => {
        if (data?.surveys?.addSurvey) {
          onSuccess({ message: `Aptauja izveidota` })
          setComplete(true)
          onComplete()
        }
      })
      .catch(error => {
        console.error(error)
        onError({ message: 'Pieprasījuma apstrādes kļūda' })
      })
  }
  return (
    <SurveyEditor
      setSurvey={onSetSurvey}
      setOpen={setOpen}
      alert={alert}
      loading={loading}
      complete={complete}
    />
  )
}
