import React from 'react'
import {
  ApolloProvider as Provider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  gql,
} from '@apollo/client'
import { useValmieraConfig } from '@mcity/valmiera-core/src/components/providers/ValmieraConfigProvider'
import { BUSINESS_TOKEN } from '@mcity/valmiera-core/src/components/providers/BusinessAuthProvider'

export default function ApolloProvider({ children }) {
  const config = useValmieraConfig()
  const cache = new InMemoryCache();
  const httpLink = createHttpLink({
    uri: config.graphqlUri,
  });

  const authLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem(BUSINESS_TOKEN);

    operation.setContext({
      headers: {
        authorization: token ? `Token ${token}` : '',
      },
    })

    return forward(operation)
  })

  const client = new ApolloClient({
    cache,
    link: authLink.concat(httpLink),
    typeDefs: gql`
      enum IncidentStatus {
        NEW
        PLANNED
        STARTED
        DONE
      }

      enum QuestionKind {
        YES_OR_NO
        TEXT
        SINGLE_CHOICE_POLL
        MULTIPLE_CHOICE_POLL
      }
    `,
  })
  return client ? <Provider client={client}>{children}</Provider> : <></>
}
