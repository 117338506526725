import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import NotificationsIcon from '@material-ui/icons/Notifications'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import routes from '../../routes/routes'
import { useBusinessAuth } from '@mcity/valmiera-core/src/components/providers/BusinessAuthProvider'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import ViewListIcon from '@material-ui/icons/ViewList'
import EventIcon from '@material-ui/icons/Event'
import HistoryIcon from '@material-ui/icons/History';
import PeopleIcon from '@material-ui/icons/People';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logoutBox: {
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
}))

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}))(MenuItem)

export default function TopBar({ children }) {
  const classes = useStyles()
  const history = useHistory()
  const { logout, isAdmin, isCompanyAdmin } = useBusinessAuth()
  const loyaltyDropDownItemsForGlobalAdmin = [
    { route: routes.business.companies, displayName: 'Uzņemumu lietotāji' },
    { route: routes.business.offers, displayName: 'Piedāvājumi' },
    { route: routes.business.transactions, displayName: 'Transakcijas' },
  ]
  const reportDropDownItemsForGlobalAdmin = [
    { route: routes.suggestions.map, displayName: 'Karte' },
    { route: routes.suggestions.list, displayName: 'Saraksts' },
  ]
  const historyDropDownItemsForGlobalAdmin = [
    { route: routes.history.companies, displayName: 'Uzņemumu vēsture' },
    { route: routes.history.offers, displayName: 'Piedāvājumu vēsture' },
    { route: routes.history.transactions, displayName: 'Transakciju vēsture' },
    { route: routes.history.surveys, displayName: 'Aptauju vēsture' },
    { route: routes.history.events, displayName: 'Pasākumu vēsture' },
  ]

  const menuItemsForCompanyAdmin = [
    { route: routes.business.profile, displayName: 'Profils' },
    { route: routes.business.offers, displayName: 'Piedāvājumi' },
    { route: routes.business.transactions, displayName: 'Transakcijas' },
  ]


  const [openLoyaltyDropdown, setOpenLoyaltyDropdown] = React.useState(null)
  const [openReportDropdown, setOpenReportDropdown] = React.useState(null)
  const [openHistoryDropdown, setOpenHistoryDropdown] = React.useState(null)

  const handleOpenLoyaltyMenu = event => {
    setOpenLoyaltyDropdown(event.currentTarget)
  }

  const handleCloseLoyaltyMenu = () => {
    setOpenLoyaltyDropdown(null)
  }

  const handleOpenHistoryMenu = event => {
    setOpenHistoryDropdown(event.currentTarget)
  }

  const handleCloseHistoryMenu = () => {
    setOpenHistoryDropdown(null)
  }

  const handleOpenLoyaltyMenuReportMenu = event => {
    setOpenReportDropdown(event.currentTarget)
  }

  const handleCloseReportMenu = () => {
    setOpenReportDropdown(null)
  }
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Valmieras admin
          </Typography>
          {isCompanyAdmin && (
            <>
              {menuItemsForCompanyAdmin.map((menuItem, ind) => (
                <Button
                  key={ind}
                  color="inherit"
                  className={classes.menuButton}
                  onClick={() => history.push(menuItem.route)}
                >
                  {menuItem.displayName}
                </Button>
              ))}
            </>
          )}
          {isAdmin && (
            <>
              <Button
                color="inherit"
                className={classes.menuButton}
                onClick={handleOpenLoyaltyMenu}
                startIcon={<LocationCityIcon />}
              >
                Lojalitāte
              </Button>
              <StyledMenu
                id="customized-menu"
                anchorEl={openLoyaltyDropdown}
                keepMounted
                open={Boolean(openLoyaltyDropdown)}
                onClose={handleCloseLoyaltyMenu}
              >
                {loyaltyDropDownItemsForGlobalAdmin.map((dropDownItem, ind) => (
                  <StyledMenuItem
                    onClick={() => history.push(dropDownItem.route)}
                    key={ind}
                  >
                    <ListItemText primary={dropDownItem.displayName} />
                  </StyledMenuItem>
                ))}
              </StyledMenu>

              <Button
                color="inherit"
                className={classes.menuButton}
                onClick={handleOpenLoyaltyMenuReportMenu}
                startIcon={<ViewListIcon />}
              >
                Ierosinājumi
              </Button>
              <StyledMenu
                id="customized-menu"
                anchorEl={openReportDropdown}
                keepMounted
                open={Boolean(openReportDropdown)}
                onClose={handleCloseReportMenu}
              >
                {reportDropDownItemsForGlobalAdmin.map((dropDownItem, ind) => (
                  <StyledMenuItem
                    onClick={() => history.push(dropDownItem.route)}
                    key={ind}
                  >
                    <ListItemText primary={dropDownItem.displayName} />
                  </StyledMenuItem>
                ))}
              </StyledMenu>
              <Button
                color="inherit"
                className={classes.menuButton}
                startIcon={<EventIcon />}
                onClick={() => history.push(routes.events)}
              >
                Pasākumi
              </Button>
              <Button
                color="inherit"
                className={classes.menuButton}
                startIcon={<NotificationsIcon />}
                onClick={() => history.push(routes.push_notifications)}
              >
                PUSH PAZIŅOJUMI
              </Button>
              <Button
                color="inherit"
                className={classes.menuButton}
                startIcon={<QuestionAnswerIcon />}
                onClick={() => history.push(routes.surveys.list)}
              >
                Aptaujas
              </Button>
              <Button
                  color="inherit"
                  className={classes.menuButton}
                  onClick={handleOpenHistoryMenu}
                  startIcon={<HistoryIcon />}
              >
                Darbību vēsture
              </Button>
              <StyledMenu
                  id="customized-menu"
                  anchorEl={openHistoryDropdown}
                  keepMounted
                  open={Boolean(openHistoryDropdown)}
                  onClose={handleCloseHistoryMenu}
              >
                {historyDropDownItemsForGlobalAdmin.map((dropDownItem, ind) => (
                    <StyledMenuItem
                        onClick={() => history.push(dropDownItem.route)}
                        key={ind}
                    >
                      <ListItemText primary={dropDownItem.displayName} />
                    </StyledMenuItem>
                ))}
              </StyledMenu>
              <Button
                color="inherit"
                className={classes.menuButton}
                startIcon={<PeopleIcon />}
                onClick={() => history.push(routes.latvija_users)}
              >
                Lietotāji
              </Button>
            </>
          )}
          <Button
            color="inherit"
            className={classes.menuButton}
            startIcon={<ExitToAppIcon />}
            onClick={() => {
              logout()
              history.push(routes.login)
            }}
          >
          </Button>
        </Toolbar>
      </AppBar>
      {children}
    </>
  )
}
