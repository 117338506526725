const endpoint = process.env.ENDPOINT || 'https://lietotne.valmiera.lv'
const cityCenter = [57.537657, 25.424329]
const mapBoxKey = 'pk.eyJ1IjoibW9iaWxseSIsImEiOiJjamx4bjFkOHQwMTRtM2twaXl0Y214cDV0In0.dVMLNDd7eHpaQLJXxO4I7w'

module.exports = {
  graphqlUri: `${endpoint}/graphql`,
  uploadUrl: `${endpoint}/rest/upload_file`,
  downloadUrl: `${endpoint}/rest/download_file`,
  downloadWithTokenUrl: `${endpoint}/rest/download_file_with_token`,
  valmieraTourismUrl: process.env.VALMIERA_TOURISM_URL || 'https://visit.valmiera.lv',
  cityCenter,
  mapBoxKey,
}
