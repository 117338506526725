import React, { useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { useMutation } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UPDATE_SUGGESTION } from '@mcity/core/lib/gql/mutations'

const STATUS_NEW = 'NEW'
const STATUS_PLANNED = 'PLANNED'
const STATUS_STARTED = 'STARTED'
const STATUS_DONE = 'DONE'

export const statusLabels = {
  [STATUS_NEW]: 'Jauns',
  [STATUS_DONE]: 'Izpildīts',
  [STATUS_STARTED]: 'Uzsākts',
  [STATUS_PLANNED]: 'Ieplānots',
}

export const statusCompareWeights = {
  [STATUS_NEW]: 0,
  [STATUS_PLANNED]: 1,
  [STATUS_STARTED]: 2,
  [STATUS_DONE]: 3,
}

export const STATUSES = [
  STATUS_NEW,
  STATUS_PLANNED,
  STATUS_STARTED,
  STATUS_DONE,
]

export default function SuggestionStatusRenderer({
  id,
  status,
  onError,
  onSuccess,
}) {
  const [value, setValue] = useState(status)
  const [updateSuggestion, { loading }] = useMutation(UPDATE_SUGGESTION)
  const onChange = event => {
    updateSuggestion({
      variables: {
        id,
        input: {
          status: event.target.value,
        },
      },
    })
      .then(({ data }) => {
        if (data?.incidents?.update) {
          setValue(data.incidents.update.status)
          onSuccess({ message: `Statuss nomainīts` })
        }
      })
      .catch(onError)
  }
  if (loading) {
    return <CircularProgress />
  }
  return (
    <Select value={value} onChange={onChange}>
      {STATUSES.map(status => (
        <MenuItem key={status} value={status}>
          {statusLabels[status]}
        </MenuItem>
      ))}
    </Select>
  )
}
