import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import SuggestionStatusRenderer from './SuggestionStatusRenderer'
import SuggestionResponsibleRenderer from './SuggestionResponsibleRenderer'
import SuggestionDueDateRenderer from './SuggestionDueDateRenderer'
import SuggestionCommentRenderer from './SuggestionCommentRenderer'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import { format } from 'date-fns'
import { lv } from 'date-fns/locale'

const useStyles = makeStyles(theme => ({
  description: {
    maxWidth: 600,
    minWidth: 200,
  },
}))

export default function SuggestionEdit({
  suggestion,
  onSuccess,
  onError,
  children,
}) {
  const classes = useStyles()
  return (
    <TableRow key={suggestion.id}>
      {children}
      <TableCell>
        <SuggestionStatusRenderer
          id={suggestion.id}
          status={suggestion.status}
          onSuccess={onSuccess}
          onError={onError}
        />
      </TableCell>
      <TableCell>
        {format(new Date(suggestion.creationDate), 'P p', { locale: lv })}
      </TableCell>
      <TableCell className={classes.description}>
        {suggestion.description}
      </TableCell>
      <TableCell style={{ minWidth: 150 }}>
        {suggestion.address}
      </TableCell>
      <TableCell>{suggestion.userId}</TableCell>
      <TableCell style={{ minWidth: 200 }}>
        <SuggestionResponsibleRenderer
          id={suggestion.id}
          responsible={suggestion.responsible}
          onSuccess={onSuccess}
          onError={onError}
        />
      </TableCell>
      <TableCell style={{ minWidth: 120 }}>
        <SuggestionDueDateRenderer
          id={suggestion.id}
          plannedDueDate={suggestion.plannedDueDate}
          onSuccess={onSuccess}
          onError={onError}
        />
      </TableCell>
      <TableCell style={{ minWidth: 200 }}>
        <SuggestionCommentRenderer
          id={suggestion.id}
          comments={suggestion.comments}
          onSuccess={onSuccess}
          onError={onError}
        />
      </TableCell>
    </TableRow>
  )
}
