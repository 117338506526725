import React from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import SaveOrCancelDialog from '../SaveOrCancelDialog'
import RichTextEditor from 'react-rte'
import { useMutation } from '@apollo/client'
import { CREATE_ATTACHMENT } from '../../../gql/mutations'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import ImagePicker from '../ImagePicker'
import { parseImageSource } from '../../../utils/imageUtils'
import CancelIcon from '@material-ui/icons/Cancel'
import { makeStyles } from '@material-ui/core/styles'
import { useValmieraConfig } from '@mcity/valmiera-core/src/components/providers/ValmieraConfigProvider'
import { useLoader } from '@mcity/core/lib/components/providers/LoaderProvider'

const useStyles = makeStyles(theme => ({
  image: {
    width: 80,
    height: 80,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    position: 'relative',
  },
  removeIcon: {
    position: 'absolute',
    top: -10,
    right: -4,
    zIndex: 2,
    height: 16,
    width: 16,
    cursor: 'pointer',
  },
}))

const defaultErrorMsg = 'Lauks ir obligāti jāaizpilda.'
export default function EventEditor({
  event,
  setEvent,
  complete,
  alert,
  loading,
  setOpen,
}) {
  const { showLoader } = useLoader()
  const classes = useStyles()
  const config = useValmieraConfig()
  const [formState, setFormState] = React.useState({
    title: '',
    intro: RichTextEditor.createEmptyValue(),
    description: RichTextEditor.createEmptyValue(),
    img: '',
    category: '',
    regions: '',
    startTime: null,
    endTime: null,
    interestEnabled: true,
  })
  const [sendPushNotifications, setSendPushNotifications] = React.useState(
    false,
  )
  const [errors, setErrors] = React.useState({})
  const [createAttachment] = useMutation(CREATE_ATTACHMENT)
  const isEditMode = Boolean(event)

  const isFormValid = () => {
    const _errors = []
    if (!formState.title) _errors.title = defaultErrorMsg
    if (!formState.intro) _errors.intro = defaultErrorMsg
    if (!formState.description) _errors.description = defaultErrorMsg
    if (!formState.startTime) _errors.startTime = defaultErrorMsg
    // TODO: endTime should be optional (backend)
    if (!formState.endTime) _errors.endTime = defaultErrorMsg

    setErrors(_errors)
    return Object.keys(_errors).length === 0
  }
  const onCreateEvent = () => {
    if (!isFormValid()) return
    setEvent({
      ...formState,
      description: formState.description.toString('html'),
      intro: formState.intro.toString('html'),
    })
  }

  React.useEffect(() => {
    if (event) {
      // if event has 'img' value is an html tag, get its src
      setFormState(_formState => ({
        title: event.title,
        img: parseImageSource(event.img),
        category: event.category,
        regions: event.regions,
        startTime: event.startTime,
        endTime: event.endTime,
        interestEnabled: event.interestEnabled,
        intro: RichTextEditor.createValueFromString(event.intro, 'html'),
        description: RichTextEditor.createValueFromString(
          event.description,
          'html',
        ),
      }))
    }
  }, [event])

  return (
    <SaveOrCancelDialog
      title={isEditMode ? 'Atjaunināt notikumu' : 'Jauns notikums'}
      onProceed={onCreateEvent}
      setOpen={setOpen}
      complete={complete}
      alert={alert}
      loading={loading}
    >
      <Box display="flex" flexDirection="column" spacing={3}>
        <TextField
          margin="normal"
          fullWidth
          size="small"
          label="Nosaukums"
          variant="outlined"
          value={formState.title}
          error={Boolean(errors.title)}
          helperText={errors.title}
          onChange={({ target: { value } }) =>
            setFormState(_formState => ({ ..._formState, title: value }))
          }
        />
        <TextField
          margin="normal"
          fullWidth
          size="small"
          label="Kategorija"
          variant="outlined"
          value={formState.category}
          error={Boolean(errors.category)}
          helperText={errors.category}
          onChange={({ target: { value } }) =>
            setFormState(_formState => ({ ..._formState, category: value }))
          }
        />
        <TextField
          fullWidth
          margin="normal"
          size="small"
          label="Novads"
          variant="outlined"
          value={formState.regions}
          error={Boolean(errors.regions)}
          helperText={errors.regions}
          onChange={({ target: { value } }) =>
            setFormState(_formState => ({ ..._formState, regions: value }))
          }
        />
        <Box mb={1} mt={1}>
          <RichTextEditor
            placeholder="Ievads"
            value={formState.intro}
            onChange={value => {
              setFormState(_formState => ({
                ..._formState,
                intro: value,
              }))
            }}
          />
        </Box>
        <Box mb={1} mt={1}>
          <RichTextEditor
            placeholder="Īss apraksts"
            value={formState.description}
            onChange={value => {
              setFormState(_formState => ({
                ..._formState,
                description: value,
              }))
            }}
          />
        </Box>
        <Box
          mt={1}
          mb={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <KeyboardDatePicker
            autoOk
            size="small"
            label="Sākuma laiks"
            variant="inline"
            inputVariant="outlined"
            value={formState.startTime}
            onChange={value =>
              setFormState(_formState => ({
                ..._formState,
                startTime: value.format(),
              }))
            }
            format="DD.MM.YYYY"
            InputAdornmentProps={{ position: 'start' }}
            error={errors.startTime}
            // helperText={errors.startTime}
          />
          <p style={{ marginLeft: 10, marginRight: 10 }}>līdz</p>
          <KeyboardDatePicker
            autoOk
            size="small"
            label="Beigu laiks"
            variant="inline"
            inputVariant="outlined"
            value={formState.endTime}
            onChange={value =>
              setFormState(_formState => ({
                ..._formState,
                endTime: value.format(),
              }))
            }
            format="DD.MM.YYYY"
            InputAdornmentProps={{ position: 'start' }}
            error={errors.endTime}
            // helperText={errors.endTime}
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={formState.interestEnabled}
              color="primary"
              onChange={({ target: { checked } }) => {
                setFormState(_formState => ({
                  ..._formState,
                  interestEnabled: checked,
                }))
              }}
            />
          }
          label="Ieinteresētā skaitītājs"
        />

        <FormControlLabel
          disabled
          control={
            <Checkbox
              checked={sendPushNotifications}
              color="primary"
              onChange={({ target: { checked } }) => {
                setSendPushNotifications(checked)
              }}
            />
          }
          label="Push paziņojums"
        />
      </Box>
      <Box>
        <Box my={2}>
          {formState.img && (
            <div
              className={classes.image}
              style={{
                backgroundImage: `url(${formState.img})`,
              }}
            >
              <div
                className={classes.removeIcon}
                onClick={() => {
                  setFormState(_formState => ({ ..._formState, img: null }))
                }}
              >
                <CancelIcon color="primary" />
              </div>
            </div>
          )}
        </Box>
        <ImagePicker
          onChange={async ({ target }) => {
            showLoader(true)
            try {
              const file = target.files?.[0]
              if (file) {
                const res = await createAttachment({
                  variables: {
                    input: {
                      name: file.name,
                    },
                  },
                })
                const attachment = res?.data?.attachments?.addAttachment
                if (attachment.id) {
                  const formData = new FormData()
                  formData.append('file', file, file.name)
                  formData.append('file_id', attachment.id)
                  formData.append('token', attachment.token)

                  const url = config.uploadUrl
                  const uploadResponse = await fetch(url, {
                    method: 'POST',
                    body: formData,
                  })
                  if (uploadResponse?.ok) {
                    setFormState(_formState => ({
                      ..._formState,
                      img: `${config.downloadUrl}?file_id=${attachment.id}&token=${attachment.token}`,
                    }))
                    console.log(
                      'uploaded event image',
                      `${config.downloadUrl}?file_id=${attachment.id}&token=${attachment.token}`,
                    )
                  } else if (uploadResponse.status === 413)
                    throw new Error('Fails ir pārāk liels')
                } else {
                  throw new Error('unknown')
                }
              }
            } catch (error) {
              console.error(error)
            } finally {
              showLoader(false)
            }
          }}
        />
      </Box>
    </SaveOrCancelDialog>
  )
}
