import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { CHANGE_COMPANY_USER_PASSWORD } from '../../gql/mutations'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import SaveOrCancelDialog from './SaveOrCancelDialog'
import { useBusinessAuth } from '@mcity/valmiera-core/src/components/providers/BusinessAuthProvider'
import { CHECK_CREDENTIALS } from '@mcity/core/lib/gql/mutations'

export default function ChangeCompanyUserPasswordTextField({
  changePasswordUsername,
  setOpen,
}) {
  const { username } = useBusinessAuth()
  const [errors, setErrors] = useState({})
  const [changePassword, { loading }] = useMutation(
    CHANGE_COMPANY_USER_PASSWORD,
  )
  const [checkCredentials] = useMutation(CHECK_CREDENTIALS)
  const [loggedInUserPassword, setLoggedInUserPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('')
  const [alert, setAlert] = useState(null)
  const [complete, setComplete] = useState(false)

  const isCurrentUser = () => changePasswordUsername === username
  const onError = ({ message }) => {
    setAlert({ severity: 'error', message: message })
  }
  const onSuccess = ({ message }) => {
    setAlert({ severity: 'success', message })
  }
  const onPasswordSave = () => {
    validateForm()
      .then(isFormValid => {
        if (isFormValid) {
          changePassword({
            variables: {
              username: changePasswordUsername,
              password: newPassword,
            },
          })
            .then(({ data }) => {
              if (data?.users?.changePassword) {
                onSuccess({ message: `Parole veiksmīgi nomainīta` })
                setComplete(true)
              }
            })
            .catch(onError)
        }
      })
      .catch(() =>
        onError({ message: 'Nav izdevies validēt ievadītās vērtības' }),
      )
  }

  async function validateForm() {
    const errors = {}
    if (loggedInUserPassword.length > 0) {
      try {
        const { data } = await checkCredentials({
          variables: {
            input: {
              username,
              password: loggedInUserPassword,
            },
          },
        })
        if (!data?.authentication?.checkCredentials) {
          errors.loggedInUserPassword = 'Lūdzu ievadiet jūsu paroli'
        }
      } catch (e) {
        errors.loggedInUserPassword = 'Lūdzu ievadiet jūsu paroli'
      }
    } else {
      errors.loggedInUserPassword = 'Lūdzu ievadiet jūsu paroli'
    }
    if (newPassword.length < 8) {
      errors.newPassword = 'Parole nevar būt īsāka par 8 simboliem'
    } else if (isCurrentUser() && newPassword === loggedInUserPassword) {
      errors.newPassword = 'Jaunā parole sakrīt ar esošo'
    }
    if (newPasswordRepeat.length === 0) {
      errors.newPasswordRepeat = 'Lūdzu ievadiet jauno paroli atkārtoti'
    } else if (newPassword !== newPasswordRepeat) {
      errors.newPasswordRepeat = 'Jaunās paroles vērtības nesakrīt'
    }
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  return (
    <SaveOrCancelDialog
      title={
        isCurrentUser()
          ? 'Paroles maiņa'
          : `Paroles maiņa lietotājam: ${changePasswordUsername}`
      }
      onProceed={onPasswordSave}
      setOpen={setOpen}
      complete={complete}
      alert={alert}
      loading={loading}
    >
      <Box mb={1}>
        <TextField
          fullWidth
          label="Jūsu parole"
          type="password"
          variant="outlined"
          value={loggedInUserPassword}
          error={
            errors.loggedInUserPassword &&
            errors.loggedInUserPassword.length > 0
          }
          helperText={errors.loggedInUserPassword}
          onChange={event => setLoggedInUserPassword(event.target.value)}
        />
      </Box>
      <Box mb={1}>
        <TextField
          fullWidth
          label={isCurrentUser() ? 'Jaunā parole' : 'Lietotāja jaunā parole'}
          type="password"
          variant="outlined"
          value={newPassword}
          error={errors.newPassword && errors.newPassword.length > 0}
          helperText={errors.newPassword}
          onChange={event => setNewPassword(event.target.value)}
        />
      </Box>
      <Box mb={1}>
        <TextField
          fullWidth
          label={
            isCurrentUser()
              ? 'Jaunā parole atkārtoti'
              : 'Lietotāja jaunā parole atkārtoti'
          }
          type="password"
          variant="outlined"
          value={newPasswordRepeat}
          error={
            errors.newPasswordRepeat && errors.newPasswordRepeat.length > 0
          }
          helperText={errors.newPasswordRepeat}
          onChange={event => setNewPasswordRepeat(event.target.value)}
        />
      </Box>
    </SaveOrCancelDialog>
  )
}
