import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import SaveOrCancelDialog from "./SaveOrCancelDialog";
import Box from "@material-ui/core/Box";

export default function CreateUserDialog({title, setOpen, onProceed, alert, loading, complete}) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const errors = {};
        if(username.length < 1) {
            errors.username = "Šīs lauks ir obligāts";
        }
        if(password.length < 8) {
            errors.password = "Parole nevar būt īsāka par 8 simboliem"
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const onDialogProceed = () => {
        if(validateForm()) {
            onProceed({username, password});
        }
    }

    return (
        <SaveOrCancelDialog
            title={title}
            onProceed={onDialogProceed}
            setOpen={setOpen}
            complete={complete}
            alert={alert}
            loading={loading}
        >
            <Box width={300} mb={1}>
                <TextField
                    fullWidth
                    label="Lietotājvārds"
                    type="username"
                    size="small"
                    variant="outlined"
                    value={username}
                    error={errors.username}
                    helperText={errors.username}
                    onChange={event => setUsername(event.target.value)}
                />
            </Box>
            <Box width={300} mb={1}>
                <TextField
                    fullWidth
                    label="Parole"
                    type="password"
                    size="small"
                    variant="outlined"
                    value={password}
                    error={errors.password}
                    helperText={errors.password}
                    onChange={event => setPassword(event.target.value)}
                />
            </Box>
        </SaveOrCancelDialog>
    )
}