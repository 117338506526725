import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Alert from '@material-ui/lab/Alert';
import CircularProgress from "@material-ui/core/CircularProgress";
import CancelIcon from '@material-ui/icons/Cancel'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    dialogTitle: {
    },
    closeIconContainer: {
        position: "absolute",
        top: 10,
        right: 10
    },
    closeIcon: {
        width: 32,
        height: 32,
        cursor: 'pointer',
    },
    button: {
        minWidth: 200,
    },
});

export default function SaveOrCancelDialog({setOpen, children, title, loading, onProceed, complete, alert}) {
    const classes = useStyles();
    const onClose = () => {
        setOpen(false);
    }
    return (
        <Dialog open
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                maxWidth="xl">
            <DialogTitle className={classes.dialogTitle}>
                <Box display="flex" justifyContent="center">
                    {title}
                </Box>
            </DialogTitle>
            <Box className={classes.closeIconContainer} display="flex" justifyContent="flex-end">
                <CancelIcon
                    className={classes.closeIcon}
                    color="primary"
                    onClick={onClose}
                />
            </Box>
            <DialogContent>
                {loading ? <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box> : !complete && children}
                <Box mb={1}>
                    {alert && <Alert severity={alert ? alert.severity : 'info'}>{alert ? alert.message : ''}</Alert>}
                </Box>
            </DialogContent>
            {complete ?
                <DialogActions>
                    <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={onClose}>
                        OK
                    </Button>
                </DialogActions> :
                <DialogActions>
                    <Button
                        fullWidth
                        disabled={loading}
                        size="large"
                        variant="contained"
                        color="secondary"
                        onClick={onClose}>
                        Atcelt
                    </Button>
                    <Button
                        fullWidth
                        disabled={loading}
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={onProceed}>
                        Saglabāt
                    </Button>
                </DialogActions>
            }
        </Dialog>
    )
}