import React from 'react'
import Container from '@material-ui/core/Container'
import { KIND_TEXT } from './SurveyQuestionEditor'
import SurveyResponseChart from './SurveyResponseChart'

export default function SurveyResponseChartList({ survey, responses }) {
  return (
    <Container>
      {survey.questions
        .filter(({ kind }) => kind !== KIND_TEXT)
        .map((question, i) => (
          <SurveyResponseChart
            key={`chart-${i}`}
            question={question}
            responses={responses.filter(
              response => response.questionId === question.baseId,
            )}
          />
        ))}
    </Container>
  )
}
