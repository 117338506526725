import React from 'react'
import HistoryBase from "./HistoryBase";
import {LIST_TRANSACTION_HISTORY} from "../../../gql/queries";
import {columns} from "../business/Transactions";
import {format} from "date-fns";
import {lv} from "date-fns/locale";

const columnKeyLabels = columns.reduce((labels, c) => {
    labels[c.field] = c.title;
    return labels;
}, {});

const keyLabels = Object.assign({
    "time": "Laiks",
    "companyId": "Uzņēmuma ID",
}, columnKeyLabels);

const keyRenderers = {
    'time': value => value ? format(new Date(Number(value)), 'P p', { locale: lv }) : "",
}

export default function TransactionHistory() {
    return <HistoryBase
        query={LIST_TRANSACTION_HISTORY}
        queryPath="transactions"
        keyLabels={keyLabels}
        keyRenderers={keyRenderers}
    />;
}