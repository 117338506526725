import React, {useState} from 'react';
import {useMutation} from "@apollo/client";
import {CREATE_COMPANY_USERS} from "../../gql/mutations";
import CreateUserDialog from "./CreateUserDialog";

export default function CreateCompanyUserDialog({companyId, isCompanyAdmin, setOpen, onComplete}) {
    const [createCompanyUsers, {loading}] = useMutation(CREATE_COMPANY_USERS);
    const [alert, setAlert] = useState(null);
    const [complete, setComplete] = useState(false);

    const onError = ({message}) => {
        setAlert({severity: 'error', message: message});
    }
    const onSuccess = ({message}) => {
        setAlert({severity: 'success', message});
    }
    const onCreateCompanyUser = ({username, password}) => {
        createCompanyUsers({
            variables:{
                companyId: companyId.toString(),
                users: [{
                    username,
                    password,
                    isAdmin: isCompanyAdmin,
                }],
            }
        })
            .then(({data}) => {
                if (data?.companies?.addUsers?.[0].success) {
                    onSuccess({message: `Lietotājs izveidots`});
                    setComplete(true);
                    onComplete();
                } else {
                    onError(({message: "Nav izdevies izveidot lietotāju"}));
                }
            })
            .catch(() => onError({message: "Pieprasījuma apstrādes kļūda"}));
    }


    return (
        <CreateUserDialog
            title={isCompanyAdmin ? "Jauns uzņēmuma administrators" : "Jauna atbildīga persona"}
            onProceed={onCreateCompanyUser}
            setOpen={setOpen}
            alert={alert}
            loading={loading}
            complete={complete} />
    );
}