import React, {useState} from 'react'
import {useQuery} from "@apollo/client";
import {GET_COMPANY_USERS} from "../../../gql/queries";
import CircularProgress from "@material-ui/core/CircularProgress";
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Box from '@material-ui/core/Box'
import Button from "@material-ui/core/Button";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import EditIcon from '@material-ui/icons/Edit';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import CreateCompanyUserDialog from "../../elements/CreateCompanyUserDialog";
import ChangeCompanyUserPasswordTextField from "../../elements/ChangeCompanyUserPasswordTextField";

export default function CompanyUsers({companyId, companyAdmins}) {
    const [createUser, setCreateUser] = useState(false);
    const [changePasswordUsername, setChangePasswordUsername] = useState(null);

    const setChangePasswordDialogOpen = open => {
        if(!open) {
            setChangePasswordUsername(null);
        }
    }

    const companyAdminUserFilter = user => {
        const isCompanyAdmin = user.params.find(p => p.key === 'companyAdmin');
        return isCompanyAdmin && isCompanyAdmin.value === "true";
    }

    const notCompanyAdminUserFilter = user => {
        return !companyAdminUserFilter(user);
    }

    const { loading, data, refetch } = useQuery(GET_COMPANY_USERS, {
        fetchPolicy: 'network-only',
        variables: { companyId: companyId },
    });
    if(loading) {
        return <CircularProgress />;
    }
    if(data?.users?.users?.list) {
        const usernames = data.users.users.list
            .filter(companyAdmins ? companyAdminUserFilter : notCompanyAdminUserFilter)
            .sort((a, b) => b.id - a.id)
            .map(({username}) => username);

        return (
            <Grid container spacing={0}>
                {
                    changePasswordUsername && <ChangeCompanyUserPasswordTextField
                        changePasswordUsername={changePasswordUsername}
                        setOpen={setChangePasswordDialogOpen} />
                }
                {
                    createUser && <CreateCompanyUserDialog
                        companyId={companyId}
                        isCompanyAdmin={companyAdmins}
                        setOpen={setCreateUser}
                        onComplete={refetch} />
                }
                <Grid item xs={12} md={12}>
                    <Button
                        onClick={() => setCreateUser(true)}
                        size="large"
                        fullWidth
                        variant="contained"
                        startIcon={<PersonAddIcon />}
                        color="primary">
                        Pievienot
                    </Button>
                </Grid>
                <Grid item xs={12} md={12}>
                    <List dense>
                        {usernames.map(username => (
                            <ListItem key={username} style={{paddingLeft: 0}}>
                                <ListItemAvatar>
                                    <Avatar >
                                        <PersonIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={username}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => setChangePasswordUsername(username)}>
                                        <EditIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        );
    }
    return "Informācija nav pieejama";
}