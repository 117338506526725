import React from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from "@material-ui/core/Button";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction
} from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EditIcon from "@material-ui/icons/Edit";

const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
        background: "rgb(235,235,235)"
    })
});

export default function DynamicItemList({
                                            children,
                                            onAppend, onRemove, onEdit, onMoved,
                                            minItemCount = 0}) {
    function onDragEnd(result) {
        // dropped outside the list
        if(!result.destination) {
            return;
        }
        if(onMoved) {
            onMoved({from: result.source.index, to: result.destination.index});
        }
    }
    return (
        <Box style={{width: '100%'}} mb={2} >
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <RootRef rootRef={provided.innerRef}>
                            <List>
                                {
                                    children.map((child, i) => {
                                        const showRemoveButton = children.length > minItemCount;
                                        return (
                                            <Draggable key={`dynamic-list-item-${i}`} draggableId={`dynamic-list-item-${i}`} index={i}>
                                                {(provided, snapshot) => (
                                                    <ListItem
                                                        ContainerComponent="li"
                                                        ContainerProps={{ ref: provided.innerRef }}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <ListItemIcon style={{minWidth: 40}}>
                                                            <DragIndicatorIcon/>
                                                        </ListItemIcon>
                                                        <Box style={{width: '100%', paddingRight: '20px'}}>
                                                            {child}
                                                        </Box>
                                                        <ListItemSecondaryAction>
                                                            {onEdit && <IconButton onClick={() => onEdit(i)}>
                                                                <EditIcon fontSize="large" />
                                                            </IconButton>}
                                                            {showRemoveButton && <IconButton onClick={() => onRemove(i)}>
                                                                <DeleteIcon fontSize="large" />
                                                            </IconButton>}
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )}
                                            </Draggable>
                                        );
                                    })
                                }
                            </List>
                        </RootRef>
                    )}
                </Droppable>
            </DragDropContext>
            <Box width={260}>
                <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon fontSize="medium" />}
                    onClick={onAppend}>
                    Pievienot
                </Button>
            </Box>
        </Box>
    )
}