import { useMutation, useQuery } from '@apollo/client'
import { Error } from '@mcity/valmiera-app/src/components/elements'
import CircularProgress from '@material-ui/core/CircularProgress'
import React, { useState } from 'react'
import { GET_SURVEYS } from '../../gql/queries'
import SurveyCreateDialog from '../elements/SurveyCreateDialog'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Box from '@material-ui/core/Box'
import SurveysTable from '../elements/surveys/SurveysTable'
import { useModal } from 'react-modal-hook'
import ConfirmDialog from '../elements/ConfirmDialog'
import { DELETE_SURVEY } from '../../gql/mutations'

export function SurveysListView() {
  const [showCreateSurvey, setShowCreateSurvey] = useState(false)
  const { loading, error, data, refetch } = useQuery(GET_SURVEYS, {
    fetchPolicy: 'network-only',
    variables: {
      limit: 1000,
      skip: 0,
    },
  })
  const [selectedSurveyId, setSelectedSurveyId] = React.useState(null)
  const [deleteSurvey] = useMutation(DELETE_SURVEY)
  const [showConfirmDialog, hideConfirmDialog] = useModal(() => {
    const hideDialog = () => {
      setSelectedSurveyId(null)
      hideConfirmDialog()
    }

    return (
      selectedSurveyId && (
        <ConfirmDialog
          text="Vai tiešām vēlaties dzēst šo aptauju?"
          onClose={hideDialog}
          onAccept={() => {
            deleteSurvey({
              variables: {
                id: selectedSurveyId,
              },
            })
              .then(response => {
                if (response?.data?.surveys?.delete) refetch()
              })
              .catch(console.error)
              .finally(() => {
                hideDialog()
              })
          }}
          onCancel={hideDialog}
        />
      )
    )
  }, [selectedSurveyId])

  if (loading) {
    return <CircularProgress />
  }
  if (error) {
    return <Error>{error.message}</Error>
  }
  if (!data?.surveys?.surveys) {
    return <Error>No data to display</Error>
  }

  return (
    <>
      {showCreateSurvey && (
        <SurveyCreateDialog
          setOpen={setShowCreateSurvey}
          onComplete={() => refetch()}
        />
      )}
      <Box
        px={2}
        mt={1}
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setShowCreateSurvey(true)
          }}
        >
          Izveidot aptauju
        </Button>
      </Box>
      <SurveysTable
        onDelete={surveyId => {
          setSelectedSurveyId(surveyId)
          showConfirmDialog()
        }}
        surveys={data.surveys.surveys}
      />
    </>
  )
}
