import { include } from 'named-urls'

export default {
  login: '/login',
  home: '/',
  push_notifications: '/push-notifications',
  latvija_users: '/latvija-users',
  events: '/events',
  suggestions: include('/suggestions', {
    list: 'list',
    map: 'map',
    details: 'details',
  }),
  business: include('/business', {
    transactions: 'transactions',
    companies: 'companies',
    offers: 'offers',
    profile: 'profile',
  }),
  surveys: include('/surveys', {
    list: '',
    responses: include('responses', {
      view: ':id',
    }),
    charts: include('charts', {
      view: ':id',
    }),
  }),
  history: include('/history', {
    transactions: 'transactions',
    companies: 'companies',
    offers: 'offers',
    events: 'events',
    surveys: 'surveys',
  }),
}
