import React from 'react'
import HistoryBase from "./HistoryBase";
import {LIST_OFFER_HISTORY} from "../../../gql/queries";
import {columns} from "../business/Offers";
import {format} from "date-fns";
import {lv} from "date-fns/locale";

const columnKeyLabels = columns.reduce((labels, c) => {
    labels[c.field] = c.title;
    return labels;
}, {});

const keyLabels = Object.assign({
    "expiry": "Derīgs līdz",
    "companyId": "Uzņēmuma ID",
}, columnKeyLabels);

const keyRenderers = {
    'expiry': value => value ? format(new Date(Number(value)), 'P', { locale: lv }) : "",
}

export default function OfferHistory() {
    return <HistoryBase query={LIST_OFFER_HISTORY} queryPath="offers" keyLabels={keyLabels} keyRenderers={keyRenderers}/>
}