import React from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import MaterialTable from '../../elements/MaterialTable'
import { lv } from 'date-fns/locale'
import { format } from 'date-fns'
import { truncate, stripHtml } from '@mcity/core/lib/utils/miscUtils'
import IconButton from '@material-ui/core/IconButton'

const EventOrderBy = {
  START_TIME: 'start_time',
  END_TIME: 'end_time',
}

const columns = [
  {
    field: 'title',
    title: 'Notikuma nosaukums',
    cellStyle: {
      minWidth: 100,
    },
  },
  {
    field: 'intro',
    title: 'Ievads',
    cellStyle: {
      minWidth: 100,
    },
  },
  {
    field: 'description',
    title: 'Apraksts',
    cellStyle: {
      minWidth: 100,
    },
  },
  { field: 'category', title: 'Kategorija' },
  { field: 'regions', title: 'Novads' },
  {
    field: 'startTime',
    title: 'Sākuma laiks',
    sortId: EventOrderBy.START_TIME,
  },
  { field: 'endTime', title: 'Beigu laiks', sortId: EventOrderBy.END_TIME },
  { field: 'interestedCount', title: 'Interesentu skaits' },
]

const reshapedEvent = ({
  startTime,
  endTime,
  intro,
  description,
  ...transaction
}) => {
  return {
    startTime: format(new Date(startTime), 'P', { locale: lv }),
    endTime: format(new Date(endTime), 'P', { locale: lv }),
    intro: truncate(stripHtml(intro)),
    description: truncate(stripHtml(description)),
    ...transaction,
  }
}

export default function EventsTable({
  data,
  refetch,
  setSelectedEventId,
  onDelete,
}) {
  const [rows, setRows] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [sortedBy, setSortedBy] = React.useState(EventOrderBy.START_TIME)
  const [orderDesc, setOrderDesc] = React.useState(true)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [totalCount, setTotalCount] = React.useState(0)

  React.useEffect(() => {
    if (data?.list) {
      setRows(data.list.map(reshapedEvent))
      setTotalCount(data.totalCount)
    }
  }, [data])

  React.useEffect(() => {
    if (refetch)
      refetch({
        skip: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: sortedBy,
        orderAscending: orderDesc,
      })
  }, [page, sortedBy, orderDesc, rowsPerPage])

  const onRowEdit = (_, { tableData, ...row }) => {
    setSelectedEventId(row.id)
  }

  const onRowDelete = (_, { tableData, ...row }) => {
    if (onDelete) {
      onDelete(row.id)
    }
  }
  return (
    <>
      <MaterialTable
        columns={columns}
        data={rows}
        actions={[
          {
            icon: 'edit',
            onClick: onRowEdit,
          },
          {
            icon: 'delete',
            onClick: onRowDelete,
          },
        ]}
        components={{
          Pagination: props => (
            <TablePagination
              {...props}
              count={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={event => {
                setRowsPerPage(Number(event.target.value))
                props.onChangeRowsPerPage(event)
              }}
              onChangePage={(_, page) => setPage(page)}
            />
          ),
          Header: data => (
            <TableHead>
              <TableRow>
                {data.columns.map(col => (
                  <TableCell key={col.field}>
                    {col.sortId ? (
                      <TableSortLabel
                        active={col.sortId === sortedBy}
                        direction={orderDesc ? 'desc' : 'asc'}
                        onClick={() => {
                          if (col.sortId === sortedBy) setOrderDesc(!orderDesc)
                          else setOrderDesc(false)

                          setSortedBy(col.sortId)
                        }}
                      >
                        {col.title}
                      </TableSortLabel>
                    ) : (
                      col.title
                    )}
                  </TableCell>
                ))}
                {/* Actions */}
                <TableCell />
              </TableRow>
            </TableHead>
          ),
        }}
        options={{
          pageSize: rowsPerPage,
        }}
      />
    </>
  )
}
