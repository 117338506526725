import React from 'react'
import { Error } from '@mcity/valmiera-app/src/components/elements'
import {useQuery} from "@apollo/client";
import MaterialTable from "../../elements/MaterialTable";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import DifferencesRenderer from "../../elements/history/DifferencesRenderer";

const HistoryOrderBy = {
    KIND: 'kind',
    IP_ADDRESS: 'audit.ipAddress',
    USER_ID: 'audit.userId',
}

const kindLabels = {
    "UPDATED": "Atjaunots",
    "CREATED": "Izveidots",
    "DELETED": "Dzēsts",
}

export default function HistoryBase({query, queryPath, keyLabels, keyRenderers}) {
    const columns = [
        { field: 'kind', title: 'Darbība', sortId: HistoryOrderBy.KIND, render: item => kindLabels[item.kind] },
        { field: 'audit.userId', title: 'Lietotāja ID', sortId: HistoryOrderBy.USER_ID },
        { field: 'audit.ipAddress', title: 'IP Adrese', sortId: HistoryOrderBy.IP_ADDRESS },
        {
            field: 'differences',
            title: 'Atšķirības',
            render: item => <DifferencesRenderer differences={item.differences} keyLabels={keyLabels} keyRenderers={keyRenderers}/>
        },
    ]

    const [rows, setRows] = React.useState([])
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [totalCount, setTotalCount] = React.useState(0)
    const [sortedBy, setSortedBy] = React.useState(HistoryOrderBy.CURRENT_ENTITY_ID)
    const [orderDesc, setOrderDesc] = React.useState(false)

    const { loading, error, data } = useQuery(query, {
        fetchPolicy: 'network-only',
        variables: {
            limit: rowsPerPage,
            skip: page * rowsPerPage,
        },
    });

    React.useEffect(() => {
        const items = data?.[queryPath]?.history?.list
        const totalCount = data?.[queryPath]?.history?.totalCount
        if (items) {
            setRows(items.map(({ __typename, ...item }) => item))
            setTotalCount(totalCount)
        }
    }, [data])
    if (error) {
        return <Error>{error.message}</Error>
    }

    return (
        <MaterialTable
            loading={loading}
            columns={columns}
            data={rows}
            components={{
                Pagination: props => (
                    <TablePagination
                        {...props}
                        count={totalCount}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={event => {
                            setRowsPerPage(Number(event.target.value))
                        }}
                        onChangePage={(_, page) => setPage(page)}
                    />
                ),
                Header: data => (
                    <TableHead>
                        <TableRow>
                            {data.columns.map(col => (
                                <TableCell key={col.field}>
                                    {col.sortId ? (
                                        <TableSortLabel
                                            active={col.sortId === sortedBy}
                                            direction={orderDesc ? 'desc' : 'asc'}
                                            onClick={() => {
                                                if (col.sortId === sortedBy) setOrderDesc(!orderDesc)
                                                else setOrderDesc(false)

                                                setSortedBy(col.sortId)
                                            }}
                                        >
                                            {col.title}
                                        </TableSortLabel>
                                    ) : (
                                        col.title
                                    )}
                                </TableCell>
                            ))}
                            <TableCell />
                        </TableRow>
                    </TableHead>
                ),
            }}
            options={{
                pageSize: rowsPerPage,
            }}
        />
    );
}