import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import EventEditor from './EventEditor'
import { CREATE_EVENT, UPDATE_EVENT } from '../../../gql/mutations'
import { GET_EVENT } from '../../../gql/queries'
import Loader from '@mcity/core/lib/components/elements/Loader'

export default function EventCreateDialog({ setOpen, onComplete, eventId }) {
  console.log({ eventId })
  const isEditMode = Boolean(eventId)
  const [createEvent, { loading }] = useMutation(CREATE_EVENT)
  const [updateEvent, { loadingUpdate }] = useMutation(UPDATE_EVENT)
  const [alert, setAlert] = useState(null)
  const [complete, setComplete] = useState(false)

  // TODO: Move this to the parent
  const { loading: loadingEventById, data: eventToEdit } = useQuery(GET_EVENT, {
    variables: {
      id: eventId,
    },
    skip: !isEditMode,
  })

  const onError = ({ message }) => {
    setAlert({ severity: 'error', message: message })
  }
  const onSuccess = ({ message }) => {
    setAlert({ severity: 'success', message })
  }
  const onSetEvent = event => {
    setAlert(null)
    const input = event
    if (!isEditMode)
      createEvent({ variables: { input } })
        .then(({ data }) => {
          if (data?.events?.create?.id) {
            onSuccess({ message: `Event created` })
            setComplete(true)
            onComplete()
          }
        })
        .catch(error => {
          console.error(error)
          onError({ message: 'Pieprasījuma apstrādes kļūda' })
        })
    else
      updateEvent({ variables: { id: eventId, input } })
        .then(({ data }) => {
          if (data?.events?.update?.id) {
            onSuccess({ message: `Event updated` })
            setComplete(true)
            onComplete()
          }
        })
        .catch(error => {
          console.error(error)
          onError({ message: 'Pieprasījuma apstrādes kļūda' })
        })
  }
  return (
    <>
      <Loader loading={loadingEventById} />
      <EventEditor
        setEvent={onSetEvent}
        setOpen={setOpen}
        alert={alert}
        loading={loading || loadingUpdate}
        complete={complete}
        event={eventToEdit?.events?.event}
      />
    </>
  )
}
