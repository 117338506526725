import { KeyboardDatePicker } from '@material-ui/pickers'
import React from 'react'

export default function OfferDueDatePicker({
  formState,
  setFormState,
  formErrors,
  disabled,
}) {
  const onChange = value => {
    setFormState(_formState => ({ ..._formState, expiry: value.format() }))
  }

  return (
    <KeyboardDatePicker
      inputVariant="outlined"
      size="small"
      fullWidth
      clearable
      disabled={disabled}
      value={formState.expiry}
      onChange={onChange}
      format="DD.MM.YYYY"
      clearLabel="Dzēst"
      cancelLabel="Atcelt"
      okLabel="Saglabāt"
      label="Aktuāls līdz"
      InputProps={{
        readOnly: true,
      }}
      helperText={formErrors.expiry}
      error={Boolean(formErrors.expiry)}
    />
  )
}
