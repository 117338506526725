import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: ({ large }) => (large ? 'column' : 'row'),
    width: '100%',
    padding: '15px 0',
    borderBottom: ({ divider }) => (divider ? '1px solid #ececec' : 'none'),
    '& > div': {
      flexShrink: 0,
    },
  },
  imageContainer: {
    width: ({ large }) => (large ? '100%' : 64),
    height: ({ large }) => (large ? '100%' : 64),
    marginRight: ({ large }) => (large ? 0 : 20),
    backgroundImage: ({ img }) => `url(${img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    maxHeight: '250px',
  },
  imagePlaceholder: {
    width: '100%',
    height: 'auto',
    visibility: 'hidden',
  },
  title: {
    width: ({ large }) => (large ? '100%' : `${window.innerWidth - 119}px`),
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    ' -webkit-line-clamp': 2,
    marginTop: ({ large }) => (large ? 10 : 0),
    marginBottom: 5,
    fontWeight: 900,
    fontSize: ({ large }) =>
      large ? theme.typography.pxToRem(20) : theme.typography.pxToRem(15),
    color: '#003057',
    overflow: 'hidden',
  },
  date: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(11),
    color: '#AC7949',
  },
}))

export default function EventCard({
  img,
  title,
  date,
  start,
  end,
  divider = true,
  large = false,
}) {
  const classes = useStyles({ img, divider, large })

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <img className={classes.imagePlaceholder} src={img} />
      </div>
      <div>
        <div className={classes.title}>{title}</div>
        <div className={classes.date}>{date}</div>
      </div>
    </div>
  )
}
