import { useQuery } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import SuggestionsTable from '../elements/suggestions/SuggestionsTable'
import SuggestionsMap from '../elements/suggestions/SuggestionsMap'
import { makeStyles } from '@material-ui/core/styles'
import Error from '@mcity/core/lib/components/elements/Error'
import { GET_VALMIERA_INCIDENTS } from '@mcity/valmiera-core/src/gql/queries'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
}))

export function SuggestionsMapView() {
  const classes = useStyles()
  const { loading, error, data } = useQuery(GET_VALMIERA_INCIDENTS, {
    fetchPolicy: 'network-only',
    variables: {
      limit: 1000,
      skip: 0,
    },
  })
  if (loading) {
    return <CircularProgress />
  }
  if (error) {
    return <Error>{error.message}</Error>
  }
  if (!data?.incidents?.incidents) {
    return <Error>No data to display</Error>
  }
  return (
    <div className={classes.root}>
      <SuggestionsMap suggestions={data.incidents.incidents} />
    </div>
  )
}

export function SuggestionsListView() {
  const classes = useStyles()
  const { loading, error, data } = useQuery(GET_VALMIERA_INCIDENTS, {
    fetchPolicy: 'network-only',
    variables: {
      limit: 1000,
      skip: 0,
    },
  })
  if (loading) {
    return <CircularProgress />
  }
  if (error) {
    return <Error>{error.message}</Error>
  }
  if (!data?.incidents?.incidents) {
    return <Error>No data to display</Error>
  }
  return (
    <div className={classes.root}>
      <SuggestionsTable suggestions={data.incidents.incidents} />
    </div>
  )
}
