import { gql } from '@apollo/client'

export const GET_ANONYMOUS_USER_ID = gql`
  query GetAnonymousUserId {
    userId @client
  }
`

export const GET_SHOW_BURGER_MENU = gql`
  query GetShowBurgerMenu {
    showBurgerMenu @client
  }
`

export const GET_EVENT_ATTENDANCE = gql`
  fragment event on EventAttendance {
    isAttending
  }
`

export const GET_EVENT_INTERESTED = gql`
  fragment event on EventInterested {
    isInterested
  }
`

export const GET_NEWS_LIST = gql`
  query GetNewsFeed($count: Number!, $lang: String!) {
    response(count: $count, lang: $lang)
      @rest(type: "Article", endpoint: "news", path: "?{args}&type=json") {
      news {
        article {
          title
          category
          url
          intro
          text
          date
          image
        }
      }
    }
  }
`

export const LIST_EVENTS_AFTER_DATE = gql`
  query ListEventsAfterDate($date: Timestamp!, $limit: Int!, $skip: Int!) {
    events {
      listEventsAfterDate(
        date: $date
        paging: { limit: $limit, skip: $skip, ascending: true }
      ) {
        id
        title
        img
        startTime
        endTime
      }
    }
  }
`

export const LIST_EVENTS = gql`
  query ListEvents {
    events {
      events(
        lookup: {
          limit: 1000
          skip: 0
          orderBy: "start_time"
          orderAscending: true
        }
      ) {
        startTime
        endTime
      }
    }
  }
`

export const GET_EVENT = gql`
  query EventsOutput($id: Long) {
    events {
      event(id: $id) {
        id
        img
        title
        startTime
        endTime
        intro
        description
        interestedCount
        isAttending @client
        isInterested @client
      }
    }
  }
`

export const GET_TOURISM_LIST = gql`
  query GetTourismList ($lang: String!){
    response (lang: $lang)
      @rest(type: "Tourism", endpoint: "tourism", path: "?{args}&type=json") {
      sections {
        section {
          id
          name
        }
      }
      regions {
        region {
          id
          name
        }
      }
      objects {
        object {
          id
          title
          region
          description
          phone
          email
          web
          address
          googleAddress
          googleCoordinates
          images {
            image {
              path
              main
            }
          }
        }
      }
    }
  }
`

export const GET_LATVIJA_LV_AUTH_URL = gql`
  query authUrl($input: AuthUrlInput!) {
    urlQuery {
      authUrl(input: $input)
    }
  }
`

export const GET_LATVIJA_LV_SIGNOUT_URL = gql`
    query SignOutUrl($input: AuthUrlInput!) {
        urlQuery{
            signOutUrl(input: $input)
        }
    }
`

export const LIST_OFFERS = gql`
  query ListOffers {
    offers {
      offers(
        lookup: {
          limit: 100
          skip: 0
          orderBy: "id"
          orderAscending: false
          filter: {
            key: "status"
            value: "APPROVED"
            flip: false
            approximate: false
          }
        }
      ) {
        list {
          id
          companyId
          title
          description
          attachmentId
          isGift
          discount
          authenticatedDiscount
          declaredDiscount
          silverDiscount
          goldDiscount
          expiry
        }
        count
        totalCount
      }
    }
  }
`

// Not available
export const GET_OFFER = gql`
  query GetOffer($id: Long!) {
    offers {
      offer(id: $id) {
        id
        companyId
        title
        description
        attachmentId
        isGift
        discount
        authenticatedDiscount
        declaredDiscount
        silverDiscount
        goldDiscount
        expiry
      }
    }
  }
`

export const GET_COMPANY = gql`
  query GetCompany($id: Long!) {
    companies {
      company(id: $id) {
        name
      }
    }
  }
`

export const GET_USER_POSITION_INFO = gql`
    query GetUserPositionInfo{
        transactions{
            userTransactionPositionInfo{
                transactionCount
                position
                totalUserCount
            }
        }
    }
`

export const GET_USER_LOYALTY = gql`
  query {
    status {
      userLoyalty
    }
  }
`

export const GET_USER_STATUS = gql`
  query {
    status {
      userStatus
    }
  }
`

export const LIST_SURVEYS = gql`
  query ListSurveys($filter: ListFilter) {
    surveys {
      surveys(
        lookup: {
          limit: 100
          skip: 0
          orderBy: "id"
          orderAscending: false
          filter: $filter
        }
      ) {
        id
        startOn
        endOn
        parameters {
          key
          value
        }
      }
    }
  }
`

export const GET_SURVEY = gql`
  query GetSurvey($id: Long!) {
    surveys {
      survey(id: $id) {
        id
        startOn
        endOn
        parameters {
          key
          value
        }
        questions {
          baseId
          kind
          required
          texts {
            lang
            text
          }
          answers {
            id
            texts {
              lang
              text
            }
          }
        }
      }
    }
  }
`

export const GET_SURVEY_RESPONSES = gql`
  query GetResponses($surveyId: Long!, $userId: Long) {
    responses {
      responses(surveyId: $surveyId, userId: $userId) {
        survey {
          id
          startOn
          endOn
          parameters {
            key
            value
          }
          questions {
            baseId
            kind
            required
            texts {
              lang
              text
            }
            answers {
              id
              texts {
                lang
                text
              }
            }
          }
        }
        responses {
          baseId
          userId
          questionId
          answerId
          freeAnswer
          responseTime
        }
      }
    }
  }
`
